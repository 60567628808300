import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxBsonService } from '@reflact/ngx-bson';
import { CreateFolderRequest, CreateFolderResonse, DeleteFileResponse, DeleteFilesRequest, MoveFileRequest, MoveFileResponse, MyFilesRequest, MyFilesResponse, NCFolderContent } from '@reflact/reflactportal';
import { BehaviorSubject } from 'rxjs';

export type UploadProgress = {
  onError: BehaviorSubject<Error | null | any>
  onStatus: BehaviorSubject<"idle" | "running" | "done">
  onProgress: BehaviorSubject<{ loaded: number, total: number }>
}

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  public currentUploadProgress: UploadProgress  // später als array wenn mehrere uploads paralell erlaubt

  constructor(public bsonService: NgxBsonService, private http: HttpClient) {
    this.currentUploadProgress = {
      onError: new BehaviorSubject<Error | null>(null),
      onProgress: new BehaviorSubject({ loaded: 0, total: 1 }),
      onStatus: new BehaviorSubject<"idle" | "running" | "done">("idle")
    }

  }

  public async getMyFiles(path: string[] = []) {
    return await this.bsonService.postBson<MyFilesRequest, MyFilesResponse>("/api/sharingspace/myfiles", {
      path
    });
  }
  public async getAllFolders() {
    return await this.bsonService.getBson<{ status: 'ok' | 'error', folders: NCFolderContent[] }>("/api/sharingspace/allfolders");
  }

  public async createFolder(path: string[], name: string) {
    const body: CreateFolderRequest = {
      path: [...path, name]
    }
    console.log("create Folder ", path)
    return await this.bsonService.postBson<CreateFolderRequest, CreateFolderResonse>("/api/sharingspace/createfolder", body)
  }

  public uploadFile(fd: FormData): UploadProgress {


    this.http.post("/api/sharingspace/upload", fd, {
      reportProgress: true,
      observe: 'events',
    }).subscribe(async res => {
      console.log("res", res)
      if (res.type == 0) {
        this.currentUploadProgress.onStatus.next('running')
      }
      if (res.type == 1) {
        console.log("res", res)
        this.currentUploadProgress.onProgress.next({ loaded: res.loaded!, total: res.total! })
      }
      if (res.type == 3) {
        this.currentUploadProgress.onStatus.next("done")
      }
    },
      error => {
        this.currentUploadProgress.onError.next(error)
      });

    return this.currentUploadProgress
  }

  public async deleteFiles(pathssss: string[]) {
    return await this.bsonService.postBson<DeleteFilesRequest, DeleteFileResponse>("/api/sharingspace/deletefiles", { paths: pathssss })
  }

  public async moveFile(from: string, to: string) {
    return await this.bsonService.postBson<MoveFileRequest, MoveFileResponse>("/api/sharingspace/movefile", { from, to })
  }
}
