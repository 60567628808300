import { Component, Input, OnInit } from '@angular/core';
import { Vkc } from '@reflact/reflactportal';

@Component({
  selector: 'app-vkc-display',
  templateUrl: './vkc-display.component.html',
  styleUrls: ['./vkc-display.component.scss']
})
export class VkcDisplayComponent implements OnInit {
  @Input({ required: true }) public vkc!: Vkc;
  @Input({ required: false }) public diameter: number = 20;
  public percentage = 0;
  ngOnInit(): void {
    let minutes = 0;
    this.vkc.tracks.forEach(t => {
      minutes += t.minutes;
    })
    if (this.vkc.supportcontingent > 0) {
      this.percentage = minutes / this.vkc.supportcontingent;
    }
  }
}
