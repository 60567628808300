<app-user-management
  [columns]="[
    {fieldName: 'email', label: 'Email'},
    {fieldName: 'name', label: 'Name'},
    {fieldName: 'reflactadmin', label: 'Reflact Admin', template:reflactAdmin}
  ]"
  [formFields]="[
    { fieldName: 'email', type: 'text', required: true },
    { fieldName: 'name', type: 'text', required: true },
    { fieldName: 'reflactadmin', type: 'switch'},
    { fieldName: 'mandantSelection', type: 'template', options: { template: globalUserMandantConfig, customData: {mandants: this.mandants} }}
  ]"
  [formFieldsCreator]="[
    { fieldName: 'email', type: 'text', required: true },
    { fieldName: 'name', type: 'text', required: true },
    { fieldName: 'reflactadmin', type: 'switch'},
    { fieldName: 'permissions', type: 'multiselect', required: true, options: { selectValues: this.permissions } },
    { fieldName: 'mandant', type: 'select', required: true, options: {selectValues: this.mandants, bindValue: '_id', bindLabel: 'name'} }
  ]"
  [formFieldCreatorData]="{email: '', name: '', reflactAdmin: false, permissions: [], mandant: null}"
  [translatedLabels]="translatedLabels"
  [service]="frontendService"
  (updateUserEvent)="updateUser($event)"
  (createUserEvent)="createUser($event)"
  [userType]="'globalFrontendUser'"
  i18n-newUserText="@@newUser"
  newUserText="Neuer Nutzer"
  [deleteButton]="false"
></app-user-management>

<ng-template
  #reflactAdmin
  let-data="data"
>
  <i
    class="ri-close-line text-danger"
    *ngIf="!data.reflactadmin"
  ></i>
  <i
    class="ri-check-line text-success"
    *ngIf="data.reflactadmin"
  ></i>
</ng-template>

<ng-template
  #globalUserMandantConfig
  let-data="data"
>
  <div>
    <h5 i18n="@@addToMandant">Zu Mandant hinzufügen</h5>

    <div class="input-group">
      <select
        class="form-select"
        id="inputGroupSelect04"
        aria-label="Example select with button addon"
        [(ngModel)]="selectedMandant"
      >
        <option
          selected
          value="undefined"
        ></option>
        <ng-container *ngFor="let mandant of mandants">
          <option
            *ngIf="!arrayContains(data.mandants, mandant._id)"
            [value]="mandant._id.toString()"
          >{{mandant.name}}</option>
        </ng-container>
      </select>
      <button
        class="btn btn-outline-success"
        type="button"
        [disabled]="selectedMandant=='undefined' ? true: null"
        (click)="addUserToMandant(data, selectedMandant)"
        i18n="@@globalUserAdd"
      >Hinzufügen</button>
    </div>

    <div class="my-3">
      <ul class="list-group">
        <li
          class="list-group-item"
          *ngFor="let mandant of data.mandants"
        >
          <ng-container *ngIf="this.mandantService.findMandant(mandant)">
            {{this.mandantService.findMandant(mandant)!.name}}
          </ng-container>

          <button
            class="btn btn-outline-danger float-end"
            type="button"
            (click)="this.removeUserFromMandant(data._id, mandant)"
            i18n="@@globalUserRemove"
          >Entfernen</button>
        </li>
      </ul>
    </div>
  </div>
</ng-template>