import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxAuthService } from '@reflact/ngx-auth';
import { NgxForm } from '@reflact/ngx-forms/lib/formtypes';
import { OverlayAsideComponent, OverlayService, ThemeService } from '@reflact/ngx-layout';
import { INavData } from '@reflact/ngx-layout/lib/body-layout/sidebar-nav/sidebar-nav.component';
import { FrontendMandant, GlobalFrontendUser } from '@reflact/reflactportal';
import { ObjectId } from 'bson';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user.service';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public navItems: INavData[] = [];
  public formFields: NgxForm.FormField[] = [
    { fieldName: "email", label: "Email", type: 'text', required: true },
    { fieldName: "name", label: "Name", type: 'text', required: true }
  ];
  public translatedLabels = new Map<string, string>([
    ["email", $localize`:@@globalEmail:Email`],
    ["name", $localize`:@@globalName:Name`]
  ])
  @ViewChild("selfUserSettings") public selfUserSettings!: OverlayAsideComponent;
  @ViewChild("authenticatorModal") public authenticatorModal!: TemplateRef<HTMLElement>;
  public mandants: FrontendMandant[] = []
  public currentMandant = "";
  public qrCodeImage = "";
  public authCode = "";

  constructor(public loginService: LoginService, public overlayService: OverlayService, public userService: UserService, public toastrService: ToastrService, public themeService: ThemeService, public router: Router, private ngxAuthService: NgxAuthService) {

  }


  async ngOnInit(): Promise<void> {
    const res = (await this.loginService.getTokenAndLogin());
    if (res.mandant != undefined && res.mandantUser != undefined) {
      if (!this.router.url.startsWith("/admin")) {
        this.navItems.push(
          {
            name: $localize`:@@navHome:Home`,
            url: "/home",
            icon: "ri-dashboard-line"
          },
          {
            name: $localize`:@@navToolsAndTrainings:Tools & Trainings`,
            url: "/portal",
            icon: "ri-pencil-ruler-2-line"
          }
        )
        this.navItems.push(
          {
            name: $localize`:@@navSharingSpace:Sharing Space`,
            url: "/sharingspace",
            icon: "ri-folders-line"
          }
        )
        this.navItems.push(
          {
            name: $localize`:@@navService:Service`,
            url: "/service",
            icon: "ri-coupon-3-line"
          }
        )
        if (res.mandantUser.permissions.indexOf("admin") > -1) {
          this.navItems.push(
            {
              name: $localize`:@@navUserAdmin:Nutzerverwaltung`,
              url: "/users",
              icon: "ri-user-line"
            }
          )
        }
      }
    }
    if (this.router.url.startsWith("/admin")) {



      if (res.globalUser?.reflactadmin) {
        this.navItems.push(
          {
            name: $localize`:@@navAdmin:Administration`,
            title: true,
            children: [
              {
                name: $localize`:@@navDashboard:Dashboard`,
                url: "/admin/dashboard",
                icon: "ri-dashboard-line"
              },
              {
                name: $localize`:@@navTileAdmin:Kachelverwaltung`,
                url: "/admin/tiles",
                icon: "ri-layout-grid-line"
              },
              {
                name: $localize`:@@navMandantAdmin:Mandantenverwaltung`,
                url: "/admin/mandants",
                icon: "ri-building-line"
              },
              {
                name: $localize`:@@navEmailAdmin:Emailverwaltung`,
                url: "/admin/emails",
                icon: "ri-mail-send-line"
              },
              {
                name: $localize`:@@navGlobalUserAdmin:Globale Nutzerverwaltung`,
                url: "/admin/users",
                icon: "ri-user-star-line"
              }
            ]
          }
        )
      }
    }

    if (this.overlayService.get("selected") == "selfuser") {
      this.openSelfUserSettings();
    }
  }




  public async getCurrentMandant() {
    let token: any = (await this.loginService.getTokenAndLogin());

    return token.mandant?._id?.toString();
  }

  public async changeMandant(mandantId: ObjectId) {
    await this.loginService.selectMandant(mandantId);
    window.location.reload();
  }

  public async openSelfUserSettings() {
    this.mandants = (await this.loginService.getMandantsForUser()).mandants;
    this.currentMandant = await this.getCurrentMandant()
    this.selfUserSettings.setSelected({ "_id": "selfuser", "data": this.loginService.loggedInUser });
  }

  public async updateSelfUser(data: GlobalFrontendUser) {
    const res = await this.userService.editMyGlobalUser(data.email, data.name);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
  }


  public async setupAuth() {
    this.qrCodeImage = (await this.loginService.setupAuthApp()).qrCode;
    this.selfUserSettings.openModal(this.authenticatorModal)
  }

  public async enableAuth() {
    const res = await this.loginService.enableAuthApp(this.authCode);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalActivated:Aktiviert!`);
      this.selfUserSettings.closeModal();
      this.loginService.getTokenAndLogin();
    }
  }

  public authStatusText() {
    return this.loginService.loggedInUser?.enableAuthentificator ? $localize`:@@deactivateAuthenticator:Authentikator deaktivieren` : $localize`:@@activateAuthenticator:Authentikator aktivieren`
  }

  public async disableAuth() {
    const res = await this.loginService.disableAuthApp();
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalDeactivated:Deaktiviert!`);
      this.loginService.getTokenAndLogin();
    }
  }

  public logoutFn() {
    localStorage.removeItem("authToken");
    window.location.href = '/'
  }

  public async registerPasskey() {
    const res = await this.ngxAuthService.registerPasskey()
    switch (res.status) {
      case 'ok':
        this.toastrService.success('Successfully registered passkey!');
        break;
      case 'alreadyregistered':
        this.toastrService.warning('Passkey already registered');
        break;
      case 'error':
        this.toastrService.error('Error registering passkey');
        break;
    }
  }

}
