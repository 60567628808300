import { Component } from '@angular/core';
import { Vkc } from '@reflact/reflactportal';
import { FaqService } from 'src/app/shared/services/faq.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  public serviceStatementVkcs: Vkc[] = [];

  constructor(public loginService: LoginService, public faqService: FaqService) {
    if (this.loginService.loggedInMandantUser?.permissions.includes('admin')) {
      this.faqService.getAccountInfo().then(r => {
        this.serviceStatementVkcs = this.faqService.serviceStatementVkcs;
      })
    }
  }

}
