<div class="card border-0 shadow">
  <div class="card-body">
    <div class="d-flex align-items-center text-muted">
      <i
        class="ri-home-2-fill cursor-pointer"
        (click)="this.changeDirectoryByBreadCrump(0)"
      ></i>
      <i class="
        ri-arrow-right-s-line
        ri-2x"></i>

      <ng-container *ngFor="let path of this.currentPath; let i = index">
        <i
          class="cursor-pointer"
          (click)="this.changeDirectoryByBreadCrump(i+1)"
        > {{path}}</i>
        <i class="ri-arrow-right-s-line ri-2x"></i>
      </ng-container>

      <input
        #input
        type="file"
        class="d-none"
        (input)="fileChosen($event); input.value = '';"
      >
      <div
        class="btn-group"
        dropdown
      >
        <button
          id="button-animated"
          dropdownToggle
          type="button"
          [disabled]="this.uploadProgress.onStatus.value == 'running'"
          aria-controls="dropdown-animated"
          class="btn btn-outline-secondary btn-sm rounded-circle"
        ><i class="ri-add-line"></i></button>
        <ul
          id="dropdown-animated"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-animated"
        >
          <li role="menuitem">
            <a
              class="dropdown-item"
              (click)="input.click()"
            ><i class="ri-file-upload-fill ri-lg text-primary"></i> <span i18n="@@sharingSpaceUploadFile">Datei
                hochladen</span></a>
          </li>
          <li role="menuitem">
            <a
              class="dropdown-item"
              (click)="this.createFolder()"
            ><i class="ri-folder-fill ri-lg text-primary"></i> <span i18n="@@sharingSpaceCreateFolder">Neuer
                Ordner</span></a>
          </li>
        </ul>
      </div>
      <ng-container *ngIf="this.uploadProgress != null && this.uploadProgress.onStatus.value == 'running'">
        <progress
          class="active ms-2"
          [value]="this.uploadProgress.onProgress.value.loaded"
          [max]="this.uploadProgress.onProgress.value.total"
        ></progress>
      </ng-container>
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th class="text-center">
          <input
            class="position-relative"
            style="top: -4px;"
            type="checkbox"
            [(ngModel)]="allSelected"
            (change)="toggleAllPathSelections()"
          >
        </th>
        <th class="w-100">
          <div class="d-flex align-items-center">
            <span>{{selectedPaths.length}} <span i18n="@@globalFiles">Dateien</span></span>
            <div
              class="btn-group"
              dropdown
            >
              <button
                id="button-animated"
                dropdownToggle
                type="button"
                class="btn btn-white  text-muted"
                aria-controls="dropdown-animated"
              >
                <i class="ri-more-line"></i> <span i18n="@@sharingSpaceActions">Aktionen</span>
              </button>
              <ul
                id="dropdown-animated"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="button-animated"
              >
                <li role="menuitem"><a
                    class="dropdown-item"
                    [ngClass]="{
                      'disabled': this.selectedPaths.length == 0,
                      'text-danger': this.selectedPaths.length > 0
                    }"
                    (click)="this.deleteFiles()"
                  ><i class="ri-delete-bin-fill"></i> <span i18n="@@sharingSpaceDeleteSelected">Ausgewählte
                      löschen</span></a></li>
              </ul>
            </div>

            <div class="btn btn-white text-muted"></div>
          </div>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let file of this.files">
        <tr
          style="vertical-align: middle;"
          [ngClass]="{'table-primary': this.selectedPaths.includes(file.filename)}"
          (click)="this.togglePathSelection(file.filename)"
        >
          <td class="text-center">
            <input
              class="mx-2"
              type="checkbox"
              [ngModel]="this.selectedPaths.includes(file.filename)"
            >
          </td>
          <td>
            <div class="d-flex align-items-center">
              <app-contenttype-icon [contentType]="this.getContentTypeForFile(file)"></app-contenttype-icon>
              <a
                *ngIf="file.type == 'file'"
                [href]="getDownloadPath(file)"
                [download]="file.basename"
                class="file"
                (click)="$event.stopPropagation()"
              >{{file.basename}}</a>
              <span
                *ngIf="file.type == 'directory'"
                (click)="this.changeDirectory($event, file)"
                class="cursor-pointer"
              >{{file.basename}}</span>
              <i
                class="ri-edit-2-line"
                style="cursor: pointer"
                (click)="this.openRenameModal($event, file)"
              ></i>
            </div>
          </td>
          <td>
            <ng-container *ngIf="file.type !== 'directory'">
              <i
                class="ri-share-forward-box-fill cursor-pointer"
                (click)="this.sendFileToTransfer(file)"
              ></i>
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="file.type !== 'directory'">
              {{(file.size | fileSize: false).split(' ').join('&nbsp;')}}
            </ng-container>
          </td>
          <td class="text-muted">
            <ng-container *ngIf="file.type !== 'directory'">
              {{dayjsHtml(file.lastmod).locale('de').fromNow().split(' ').join('&nbsp;')}}
            </ng-container>
          </td>
          <td class="px-4">
            <i
              class="ri-folder-transfer-line cursor-pointer"
              (click)="this.openMoveModal($event, file)"
            ></i>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td class="border-0"></td>
        <td class="border-0 pt-3 text-muted fw-bold">{{this.files.length}} <span i18n="@@globalFiles">Dateien</span>
        </td>
        <td class="border-0"></td>
        <td class="border-0 pt-3 text-muted fw-bold">
          <ng-container *ngIf="this.globalFileSize > 0">
            {{(this.globalFileSize | fileSize: false).split(' ').join('&nbsp;')}}
          </ng-container>
        </td>
        <td class="border-0"></td>
        <td class="border-0"></td>
      </tr>
    </tbody>
  </table>
</div>



<ng-template #renameFileModal>
  <div
    class="m-4"
    *ngIf="this.fileToMove"
  >
    <h3 class="text-center">
      <ng-container
        *ngIf="this.fileToMove.type == 'directory'"
        i18n="@@sharingSpaceRenameFolder"
      >Ordner Umbenennen</ng-container>
      <ng-container
        *ngIf="this.fileToMove.type == 'file'"
        i18n="@@sharingSpaceRenameFile"
      >Datei Umbenennen</ng-container>
    </h3>
    <div class="m-3">
      <div class="row mb-3 align-items-center">
        <div
          class="col-4"
          i18n="@@sharingSpaceOldName"
        >Alter Name:</div>
        <div class="col-8">
          <div
            class="rounded-2"
            style="background-color: #d8dbe0; padding: 0.375rem 0.75rem;"
          >{{this.fileToMove.basename}}</div>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div
          class="col-4"
          i18n="@@sharingSpaceNewName"
        >Neuer Name:</div>
        <div class="col-8">
          <input
            #newFileName
            id="newFileName"
            class="form-control"
            [(ngModel)]="this.fileToMoveName"
          >
        </div>
      </div>

      <small
        class="text-danger"
        *ngIf="this.fileToMove.type == 'file' && this.fileToMoveName.split('.').pop() != this.fileToMove.basename.split('.').pop()"
        i18n="@@sharingSpaceRenameFileWarning"
      >Beim ändern der Dateiendung könnte die Datei beschädigt werden.</small>
      <small
        class="text-danger"
        *ngIf="this.fileAlreadyExists"
      ><ng-container
          *ngIf="this.fileToMove.type == 'directory'"
          i18n="@@sharingSpaceFolderAlreadyExists"
        >Ordner mit diesem Namen existiert bereits!</ng-container>
        <ng-container
          *ngIf="this.fileToMove.type == 'file'"
          i18n="@@sharingSpaceFileAlreadyExists"
        >Datei mit diesem Namen existiert bereits!</ng-container>
      </small>
      <div class="text-end">
        <button
          class="btn btn-primary btn-sm"
          [disabled]="this.fileToMoveName == this.fileToMove.basename"
          (click)="this.moveFile(this.currentPath.join('/') + '/' + this.fileToMove.basename, this.currentPath.join('/') + '/' + this.fileToMoveName)"
          i18n="@@ngxFormsSaveButton"
        >Speichern</button>
      </div>

    </div>
  </div>
</ng-template>
<ng-template #moveFileModal>
  <div
    class="m-4"
    *ngIf="this.fileToMove"
  >
    <h3 class="text-center">
      <ng-container
        *ngIf="this.fileToMove.type == 'directory'"
        i18n="@@sharingSpaceMoveFolder"
      >Ordner Verschieben</ng-container>
      <ng-container
        *ngIf="this.fileToMove.type == 'file'"
        i18n="@@sharingSpaceMoveFile"
      >Datei Verschieben</ng-container>
    </h3>
    <div class="m-3">
      <div class="row mb-3 align-items-center">
        <div
          class="col-4"
          i18n="@@sharingSpaceOldPath"
        >Alter Pfad:</div>
        <div class="col-8">
          <div
            class="rounded-2"
            style="background-color: #d8dbe0; padding: 0.375rem 0.75rem;"
          >/{{this.currentPath.join('/') !== '' ? this.currentPath.join('/')+ '/' : ''}}<span
              class="text-muted">{{this.fileToMove.basename}}</span></div>
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div
          class="col-4"
          i18n="@@sharingSpaceNewPath"
        >Neuer Pfad:</div>
        <div class="col-8">
          <ng-select
            id="newPathName"
            [(ngModel)]="this.fileToMovePath"
          >
            <ng-option
              *ngFor="let path of this.allAvaliableFolders"
              [value]="path.filename"
            >/{{path.filename !== '' ? path.filename + '/' : ''}}<span
                class="text-muted">{{this.fileToMove.basename}}</span></ng-option>
          </ng-select>
        </div>
      </div>
      <small
        class="text-danger"
        *ngIf="this.fileAlreadyExists"
        i18n="@@sharingSpaceTargetAlreadyExists"
      >Ziel mit diesem Namen existiert bereits!</small>
      <div class="text-end">
        <button
          class="btn btn-primary btn-sm"
          [disabled]="this.fileToMovePath == this.currentPath.join('/')"
          (click)="this.moveFile(this.currentPath.join('/') + '/' + this.fileToMove.basename, this.fileToMovePath + '/' + this.fileToMove.basename)"
          i18n="@@ngxFormsSaveButton"
        >Speichern</button>
      </div>
    </div>
  </div>
</ng-template>