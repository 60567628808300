import { Component } from '@angular/core';
import { NgxForm } from '@reflact/ngx-forms/lib/formtypes';
import { RagDatasupplyFrontendService } from '@reflact/rag-datasupply';
import { Permissions, User, Vkc } from '@reflact/reflactportal';
import { ToastrService } from 'ngx-toastr';
import { FaqService } from 'src/app/shared/services/faq.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  templateUrl: './user-administration.component.html',
  styleUrls: ['./user-administration.component.scss'],
  providers: [RagDatasupplyFrontendService]
})
export class UserAdministrationComponent {
  public permissions: Permissions[] = ["admin", "service", "kitools-admin"];
  public vkcs: Vkc[] = [];
  public translatedLabels: NgxForm.TranslatedLabels = new Map<string, string>([
    ["globalUser.email", $localize`:@@globalEmail:Email`],
    ["globalUser.name", $localize`:@@globalName:Name`],
    ["permissions", $localize`:@@globalUserAdminPermissions:Berechtigungen`],
    ["excludeCrmIds", $localize`:@@globalUserAdminExcludeCrmIds:Ausgeschaltete CRM-IDs`],
  ])

  constructor(public frontendService: RagDatasupplyFrontendService<User>, private userService: UserService, private toastrService: ToastrService, private faqService: FaqService) {
    this.userService.getMandantUsers().then(u => this.frontendService.fromArray(u));
  }

  public async updateUser(data: User) {
    const res = await this.userService.editMandantUser(data._id, data.permissions, data.excludeCrmIds);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.userService.getMandantUsers().then(u => this.frontendService.fromArray(u))
  }

  public async createUser(data: any) {
    const res = await this.userService.createUser(data.globalUser.email, data.globalUser.name, data.permissions, []);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.userService.getMandantUsers().then(u => this.frontendService.fromArray(u))
  }

  public async deleteUser(data: User) {
    const res = await this.userService.deleteMandantUser(data._id);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.userService.getMandantUsers().then(u => this.frontendService.fromArray(u))
  }
}