import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxBsonService } from '@reflact/ngx-bson';
import { CreateTileRequest, DefaultResponse, DeleteTileRequest, EditTileRequest, ListTilesResponse, Permissions, Tile } from '@reflact/reflactportal';
import { ObjectId } from 'bson';

@Injectable({
  providedIn: 'root'
})
export class TileService {

  constructor(public http: HttpClient, public bsonService: NgxBsonService) { }

  public async listTiles(): Promise<Tile[]> {
    const res = await this.bsonService.getBson<ListTilesResponse>("/api/tile/list")
    if (res.status == "ok") {
      return res.tiles;
    }
    return [];
  }

  public async listMyTiles(): Promise<Tile[]> {
    const res = await this.bsonService.getBson<ListTilesResponse>("/api/tile/listMy");
    if (res.status == "ok") {
      return res.tiles;
    }
    return [];
  }

  public async createTile(name: string, showByDefault: boolean, text: string, subtext: string, url: string, img: string | undefined, openInNewTab: boolean, color: string, icon: string, permissions: Permissions[]): Promise<DefaultResponse> {
    const req: CreateTileRequest = {
      name,
      showByDefault,
      text,
      subtext,
      url,
      img,
      openInNewTab,
      color,
      icon,
      permissions
    }
    const res = await this.bsonService.postBson<CreateTileRequest, DefaultResponse>("/api/tile/create", req);
    return res;
  }

  public async editTile(tile: EditTileRequest): Promise<DefaultResponse> {
    const res = await this.bsonService.postBson<EditTileRequest, DefaultResponse>("/api/tile/edit", tile);
    return res;
  }

  public async deleteTile(tileId: ObjectId): Promise<DefaultResponse> {
    const req: DeleteTileRequest = {
      "tileId": tileId
    }
    const res = await this.bsonService.postBson<DeleteTileRequest, DefaultResponse>("/api/tile/delete", req);
    return res;
  }


}
