<div
  class="card border-0 shadow"
  *ngIf="this.loginService.loggedInUser"
>
  <div class="card-body">
    <lib-mail-manager
      #mailmanager
      [httpHeaderFunction]="this.loginService.getAuthHeaders"
      [showTableAddons]="false"
      [adminView]="true"
      [ccEnabled]="false"
      [icalEnabled]="false"
      [lang]="'de'"
      [mail]="this.loginService.loggedInUser.email"
      [nameIsEditable]="true"
      [languageToFlagMapping]="{en: 'gb'}"
    ></lib-mail-manager>
  </div>
</div>