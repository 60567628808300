import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { DefaultResponse, PortalJwtPayload } from '@reflact/reflactportal';
import { LoginService } from '../services/login.service';

export const adminGuard: CanActivateFn = async (route, state) => {
  const loginService: LoginService = inject(LoginService);
  const router: Router = inject(Router);
  let res: DefaultResponse & Partial<PortalJwtPayload> = (await loginService.getTokenAndLogin())
  if (res.status == "ok") {
    if (res.globalUser?.reflactadmin) return true;
  }
  router.navigateByUrl("portal")
  return false;
};
