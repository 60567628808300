<div class="row row-cols-1 row-cols-md-3 g-4">
  <div
    class="col-sm-12 col-md-6 col-lg-4"
    *ngFor="let tile of tiles"
  >
    <div class="tile-card shadow">
      <img
        class="tile-img "
        src="{{tile.img}}"
        [ngStyle]="{'background-color': tile.color + '22'}"
        alt=""
      >
      <a
        [href]="getTileUrl(tile.url)"
        target="{{tile.openInNewTab ? '_blank' : '_self'}}"
        class="row align-items-center tile-controlls text-decoration-none"
      >
        <div class="col-auto">
          <img
            class="tile-icon"
            src="{{tile.icon}}"
            alt=""
          >
        </div>
        <div class="col">
          <b>{{tile.text}}</b><br>
          <small>{{tile.subtext}}</small>
        </div>
        <div class="col-auto">
          <i
            class="tile-href ri-arrow-right-s-line text-white text-center"
            [ngClass]="{'{{tiles.icon}}': true}"
            [ngStyle]="{'background-color': tile.color}"
          ></i>
        </div>
      </a>
    </div>
  </div>
</div>