import { Component } from '@angular/core';
import { NgxForm } from '@reflact/ngx-forms/lib/formtypes';
import { RagDatasupplyFrontendService } from '@reflact/rag-datasupply';
import { FrontendMandant, GlobalFrontendUser, Permissions } from '@reflact/reflactportal';
import { ObjectId } from 'bson';
import { ToastrService } from 'ngx-toastr';
import { MandantService } from 'src/app/shared/services/mandant.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  templateUrl: './global-user-administration.component.html',
  styleUrls: ['./global-user-administration.component.scss'],
  providers: [RagDatasupplyFrontendService]
})
export class GlobalUserAdministrationComponent {
  public permissions: Permissions[] = ["admin", "service", "kitools-admin"];
  public mandants: FrontendMandant[] = [];
  public selectedMandant: string = "undefined";
  public translatedLabels: NgxForm.TranslatedLabels = new Map<string, string>([
    ["email", $localize`:@@globalEmail:Email`],
    ["name", $localize`:@@globalName:Name`],
    ["reflactadmin", $localize`:@@globalUserAdminReflactAdmin:Reflact Admin`],
    ["permissions", $localize`:@@globalUserAdminPermissions:Berechtigungen`],
    ["mandant", $localize`:@@globalMandant:Mandant`],
    ["mandantSelection", $localize`:@@globalUserAdminMandantSelection:Mandant Selektierung`],
  ])
  constructor(public frontendService: RagDatasupplyFrontendService<GlobalFrontendUser>, private userService: UserService, private toastrService: ToastrService, public mandantService: MandantService) {
    this.userService.getGlobalUsers().then(u => this.frontendService.fromArray(u))
    mandantService.getMandants().then(m => {
      this.mandants = m;
    });
  }

  public async updateUser(data: GlobalFrontendUser) {
    const res = await this.userService.editGlobalUser(new ObjectId(data._id), data.email, data.enableAuthentificator, data.name, data.reflactadmin);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.userService.getGlobalUsers().then(u => this.frontendService.fromArray(u))
  }

  public async createUser(data: any) {
    const res = await this.userService.createUserAsReflactAdmin(data.email, data.name, data.permissions, data.mandant, data.reflactadmin, []);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.userService.getGlobalUsers().then(u => this.frontendService.fromArray(u))
  }

  public arrayContains(data: any[], element: ObjectId) {
    return data.find((id: string) => id == element.toString()) != undefined
  }

  public async addUserToMandant(data: any, mandantId: string) {
    const permissions: Permissions[] = data.reflactadmin ? ["admin", "service"] : []
    this.userService.createUserAsReflactAdmin(data.email, data.name, permissions, new ObjectId(mandantId), data.reflactadmin, []);
    this.userService.getGlobalUsers().then(u => this.frontendService.fromArray(u))
  }

  public async removeUserFromMandant(userId: ObjectId, mandantId: ObjectId) {
    this.userService.deleteMandantUserAsReflactAdmin(userId, mandantId);
    this.userService.getGlobalUsers().then(u => this.frontendService.fromArray(u));
  }
}