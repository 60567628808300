import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-simple-pie',
  templateUrl: './simple-pie.component.html',
  styleUrls: ['./simple-pie.component.scss']
})
export class SimplePieComponent implements OnInit, OnChanges {
  @Input({ required: true }) public percent: number = 0;
  @Input() public color!: string;
  @Input() public autoColor: boolean = true;
  @Input() public diameter: number = 25;
  @Input() public autosize: boolean = false;
  @Input() public backgroundColor: string = "#fff";
  @Input() public middleDot: boolean = false;
  @Input() public middleDotColor: string = "#fff";

  public svgHtml: SafeHtml = "";

  constructor(private sanitizer: DomSanitizer) { }

  private getColor() {
    return this.percent < 90 ? this.percent > 75 ? '#FFC007' : '#0074d9' : '#ff4136'
  }

  ngOnInit(): void {
    this.getPieChart()
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['percent'].currentValue != changes['percent'].previousValue) {
      this.getPieChart()
    }
  }

  public getPieChart() {
    this.svgHtml = this.sanitizer.bypassSecurityTrustHtml(
      `<svg
  height="${this.autosize ? '100%' : this.diameter}"
  width="${this.autosize ? '100%' : this.diameter}"
  viewBox="0 0 20 20"
>
  <circle
    r="10"
    cx="10"
    cy="10"
    fill="${this.backgroundColor}"
  />
  <circle
    r="5"
    cx="10"
    cy="10"
    fill="transparent"
    stroke="${this.autoColor ? this.getColor() : this.color}"
    stroke-width="10"
    stroke-dasharray="calc(${this.percent} * 31.4 / 100) 31.4"
    transform="rotate(-90) translate(-20)"
  />
  ${this.middleDot == true ? `<circle
    r="9"
    cx="10"
    cy="10"
    fill="${this.middleDotColor}"
  />`: ''}
</svg>`);
  }

}
