import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NCFolderContent } from '@reflact/reflactportal';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SubscriptionLike } from 'rxjs';
import { LoginService } from 'src/app/shared/services/login.service';
import { SharingService } from 'src/app/shared/services/sharing.service';

@Component({
  selector: 'app-sharing-space',
  templateUrl: './sharing-space.component.html',
  styleUrls: ['./sharing-space.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class SharingSpaceComponent implements OnInit, OnDestroy {

  public files: NCFolderContent[] = [];
  public chosenFile?: any;
  public chosen: boolean = false;
  public selectedPaths: string[] = [];
  public currentPath: string[] = [];
  public allSelected: boolean = false;
  public dayjsHtml = dayjs;
  public subscriptions: SubscriptionLike[] = [];
  public modalRef?: BsModalRef;

  public fileToMove?: NCFolderContent;
  public fileToMoveName: string = "";
  public fileToMovePath: string = "";
  public allAvaliableFolders: NCFolderContent[] = [];
  public fileAlreadyExists: boolean = false;

  @ViewChild("renameFileModal") public renameFileModal!: TemplateRef<HTMLElement>;
  @ViewChild("moveFileModal") public moveFileModal!: TemplateRef<HTMLElement>;

  constructor(public sharingService: SharingService, public loginService: LoginService, public router: Router, public activeRoute: ActivatedRoute, public modalService: BsModalService) {
    this.dayjsHtml.extend(relativeTime)
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  public get uploadProgress() {
    return this.sharingService.currentUploadProgress
  }
  async ngOnInit(): Promise<void> {
    this.subscriptions.push(this.uploadProgress.onStatus.subscribe(val => {
      if (val == "done") {
        this.chosenFile = undefined;
        this.chosen = false;
        this.loadFiles()
      }
    }))

    this.subscriptions.push(this.activeRoute.fragment.subscribe(fragment => {
      if (fragment != null && fragment.length > 0) {
        this.currentPath = fragment.split("/")
      }
      this.loadFiles();
    }));
    this.sharingService.getAllFolders().then(resp => {
      this.allAvaliableFolders = resp.folders;
    })
  }

  public async loadFiles() {
    const resp = await this.sharingService.getMyFiles(this.currentPath)
    this.files = resp.files
    this.files.sort((a, b) => {
      if (a.type == b.type) {
        return a.basename.localeCompare(b.basename)
      }
      if (a.type == "directory") {
        return -1
      }
      return 1
    })
    this.router.navigate([], { fragment: this.currentPath.join("/") });
  }

  public fileChosen(event: any) {
    if (event.target.value) {
      this.chosenFile = <File>event.target.files[0]
      this.chosen = true;
      this.uploadFile();
    }
  }

  public sendFileToTransfer(file: NCFolderContent) {
    const targetUrl = "/filetransfer/" + this.loginService.getApplicationLanguage() + "/urlshare/" + encodeURIComponent(file.basename) + "/" + encodeURIComponent(window.location.origin + this.getDownloadPath(file, true)) + "/" + file.size
    console.log(targetUrl)
    window.open(targetUrl)
  }

  public async createFolder() {
    const name = prompt("Name?");
    if (name !== null && name != '') {
      await this.sharingService.createFolder(this.currentPath, name);
      this.currentPath.push(name);
      this.loadFiles();
    }
  }

  public togglePathSelection(path: string) {
    if (this.selectedPaths.includes(path)) {
      this.selectedPaths = this.selectedPaths.filter(p => p != path);
    } else {
      this.selectedPaths.push(path);
    }
    this.allSelected = this.selectedPaths.length == this.files.length;
  }

  public async changeDirectoryByBreadCrump(crumpIndex: number) {
    this.currentPath.splice(crumpIndex)
    this.loadFiles()
  }

  public async changeDirectory(event: any, file: NCFolderContent) {
    event.stopPropagation();
    this.currentPath.push(file.basename)
    this.loadFiles()
  }

  public toggleAllPathSelections() {
    if (this.selectedPaths.length == this.files.length) {
      this.selectedPaths = [];
      this.allSelected = false;
    } else {
      this.selectedPaths = this.files.map(f => f.filename)
      this.allSelected = true;
    }
  }

  public async deleteFiles() {
    await this.sharingService.deleteFiles(this.selectedPaths)
    await this.loadFiles();
    this.selectedPaths = [];
    this.allSelected = false;
  }

  public uploadFile() {
    let fd = new FormData();
    if (this.chosenFile) {
      fd.append("ncPath", this.currentPath.join("/"))
      fd.append('file', this.chosenFile, this.chosenFile.name);
      this.sharingService.uploadFile(fd)



    }
  }

  public openRenameModal(event: Event, file: NCFolderContent) {
    event.stopPropagation();
    this.fileToMove = file;
    this.fileToMoveName = file.basename
    this.modalRef = this.modalService.show(this.renameFileModal);
    this.modalRef.onHide?.subscribe(() => {
      this.fileToMove = undefined;
      this.fileToMoveName = "";
      this.fileAlreadyExists = false;
    })
    setTimeout(() => {
      document.getElementById("newFileName")!.focus();
      (document.getElementById("newFileName")! as HTMLInputElement).setSelectionRange(0, file.basename.lastIndexOf("."));
    }, 1000)
  }

  public openMoveModal(event: Event, file: NCFolderContent) {
    event.stopPropagation();
    this.fileToMove = file;
    this.fileToMovePath = this.currentPath.join("/");
    this.modalRef = this.modalService.show(this.moveFileModal);
    this.modalRef.onHide?.subscribe(() => {
      this.fileToMove = undefined;
      this.fileToMovePath = "";
      this.fileAlreadyExists = false;
    })

    setTimeout(() => {
      document.getElementById("newPathName")!.focus();
    }, 1000)
  }

  public async moveFile(from: string, to: string) {
    const res = await this.sharingService.moveFile(from, to)
    if (res.status == "error") {
      this.fileAlreadyExists = true;
      return;
    }
    await this.loadFiles();
    this.modalRef?.hide();
  }

  public getDownloadPath(file: NCFolderContent, encodeFileName: boolean = true) {
    const filename = encodeFileName ? encodeURIComponent(file.filename) : file.filename
    return "/api/sharingspace/file/" + filename + '?access_token=' + localStorage.getItem("authToken")
  }

  public get globalFileSize(): number {
    let globalSize = 0;
    this.files.map(f => f.size).forEach(s => globalSize += s);
    return globalSize;
  }

  public getContentTypeForFile(foldercontent: NCFolderContent) {
    return foldercontent.type == 'directory' ? 'application/x-directory' : foldercontent.mime!
  }
}