import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxBsonService } from '@reflact/ngx-bson';
import { DefaultResponse, EnableAuthAppRequest, FrontendMandant, GlobalFrontendUser, LoginRequest, LoginResponse, MandantIdRequest, MyMandantsResponse, PortalJwtPayload, SelectMandantResponse, SetupAuthAppResponse, User, VerificationCodeRequest } from '@reflact/reflactportal';
import { ObjectId } from 'bson';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public loggedInUser$ = new BehaviorSubject<GlobalFrontendUser | undefined>(undefined);
  public loggedInUser: GlobalFrontendUser | undefined = undefined;
  public loggedInMandantUser: User | null = null;
  public selectedMandant$ = new BehaviorSubject<FrontendMandant | null>(null)

  public token: string | undefined = undefined

  constructor(private http: HttpClient, public bsonService: NgxBsonService) {
    const token = localStorage.getItem('authToken');
    if (token != null) {
      this.token = token;
    }
  }
  public getApplicationLanguage() {
    return (window.location.href.indexOf("/portal/de") >= 0) ? "de" : "en"
  }

  public getTokenAndLogin(): DefaultResponse & Partial<PortalJwtPayload> {
    const token = localStorage.getItem('authToken');
    if (token == null) {
      return { status: 'forbidden' };
    }
    this.token = token
    const decoded = jwtDecode<PortalJwtPayload & JwtPayload>(token);
    if (decoded.globalUser == null || (decoded.exp != null && new Date(decoded.exp * 1000).getTime() <= new Date().getTime())) {
      localStorage.removeItem("authToken")
      return { status: 'forbidden' };
    }
    if (decoded.globalUser) {
      this.selectedMandant$.next(decoded.mandant)
      this.loggedInUser = decoded.globalUser
      this.loggedInUser$.next(decoded.globalUser)
      this.loggedInMandantUser = decoded.mandantUser;
    }
    return { status: 'ok', ...decoded }
  }

  public async refreshToken(): Promise<LoginResponse | DefaultResponse> {
    if (localStorage.getItem("authToken") == undefined) return { status: 'error' };
    const res = await this.bsonService.getBson<LoginResponse>("/api/auth/refreshtoken").catch((r: HttpErrorResponse) => {
      if (r) {
        console.log(r)
      }
    })
    if (!res) return { status: "forbidden" };
    if (res.status == "ok") {
      localStorage.setItem("authToken", res.token);
      this.token = res.token;
    } else {
      localStorage.removeItem("authToken");
      window.location.reload()
    }
    return res;
  }

  public async sendLoginCodeForEmail(email: string): Promise<DefaultResponse> {
    const req: VerificationCodeRequest = {
      email
    }
    const res = await this.bsonService.postBson<VerificationCodeRequest, DefaultResponse>("/api/auth/verificationCode", req);
    return res;
  }


  public async loginWithCode(email: string, code: string): Promise<PortalJwtPayload | undefined> {
    const req: LoginRequest = {
      email,
      verificationCode: code
    }
    const res = await this.bsonService.postBson<LoginRequest, LoginResponse>("/api/auth/login", req);
    if (res.status == "ok") {
      // hier wollen wir noch den User haben
      const payload: PortalJwtPayload = jwtDecode(res.token)
      this.token = res.token;
      localStorage.setItem("authToken", res.token);
      if (payload.globalUser) {
        this.loggedInUser = payload.globalUser;
        this.loggedInUser$.next(payload.globalUser)
        this.selectedMandant$.next(payload.mandant)
      }
      return payload
    }
    return undefined;
  }

  public async loginWithToken(token: string): Promise<PortalJwtPayload | undefined> {
    if (!token) return undefined;
    const payload: PortalJwtPayload = jwtDecode(token);
    this.token = token;
    localStorage.setItem("authToken", token);
    if (payload.globalUser) {
      this.loggedInUser = payload.globalUser;
      this.loggedInUser$.next(payload.globalUser)
      this.selectedMandant$.next(payload.mandant)
    }
    return payload;
  }

  public getAuthHeaders(): any {
    return {
      token: localStorage.getItem('authToken')
    };
  }

  public getAuthorizationToken(): string | undefined {
    if (this.token == undefined) {
      return undefined;
    }
    return 'Bearer ' + this.token;
  }

  public async getMandantsForUser(): Promise<MyMandantsResponse> {
    const res = await this.bsonService.getBson<MyMandantsResponse>("/api/user/myMandants");
    return res;
  }

  public async selectMandant(mandantId: ObjectId): Promise<SelectMandantResponse> {
    const req: MandantIdRequest = {
      mandantId
    }
    const res = await this.bsonService.postBson<MandantIdRequest, SelectMandantResponse>("/api/auth/selectMandant", req);

    localStorage.setItem("authToken", res.token)
    await this.getTokenAndLogin();
    return res;
  }

  public async setupAuthApp(): Promise<SetupAuthAppResponse> {
    const res = await this.bsonService.getBson<SetupAuthAppResponse>("/api/auth/setupAuthApp");
    return res;
  }

  public async enableAuthApp(token: string) {
    const req: EnableAuthAppRequest = {
      "authAppToken": token
    }
    const res = await this.bsonService.postBson<EnableAuthAppRequest, DefaultResponse>("/api/auth/enableAuthApp", req);
    await this.refreshToken();
    return res;
  }

  public async disableAuthApp() {
    const res = await this.bsonService.postBson<{}, DefaultResponse>("/api/auth/disableAuthApp", {});
    await this.refreshToken();
    return res;
  }



}

