import { Component, OnInit } from '@angular/core';
import { Tile } from '@reflact/reflactportal';
import { TileService } from 'src/app/shared/services/tile.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  templateUrl: './tools-and-trainings.component.html',
  styleUrls: ['./tools-and-trainings.component.scss']
})
export class ToolsAndTrainingsComponent implements OnInit {
  constructor(public userService: UserService, public tileServide: TileService) {
  }



  public tiles: Tile[] = [];

  async ngOnInit() {
    this.tiles = await this.tileServide.listMyTiles();
  }
  public getTileUrl(tileUrl: string) {
    let returnUrl = tileUrl;
    returnUrl = returnUrl.replace("{portalLoginToken}", encodeURIComponent(localStorage.getItem("authToken")!));
    returnUrl = returnUrl.replace("{username}", encodeURIComponent(this.userService.loginService.loggedInUser?.name!));
    returnUrl = returnUrl.replace("{short}", encodeURIComponent(this.userService.loginService.selectedMandant$.value!.short));

    // TODO: replace {portalLoginToken} and {username} here
    return returnUrl
  }

}
