import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const externalLoginGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const token = route.paramMap.get("token")
  const target = route.paramMap.get("target")

  console.log("external login guard routes to ", route.params)
  localStorage.setItem("authToken", "" + token);
  if (target == null) {
    router.navigate([])
  } else {
    router.navigateByUrl(target)
  }
  return true;
};
