<rag-body-layout *ngIf="this.navItems.length > 0" [navItems]="navItems"
  [logoUrl]="'assets/portallogo_' + themeService.current + 'mode.svg'"
  [loggedInUser]="{name:loginService.loggedInUser?.name!}" [logoutFunction]="this.logoutFn.bind(this)"
  [userEditClick]="this.openSelfUserSettings.bind(this)" [enableThemeSwitcher]="false">
  <router-outlet></router-outlet>
</rag-body-layout>


<rag-overlay-aside #selfUserSettings>
  <div header *ngIf="selfUserSettings.selectedObject">
    <h1 i18n="@@globalProfile">Profil</h1>
  </div>
  <div *ngIf="selfUserSettings.selectedObject">
    <rag-form [formFields]="formFields" [data]="selfUserSettings.selectedObject.data"
      [translatedLabels]="translatedLabels" (submit)="updateSelfUser($event);" [hideDeleteButton]="true"></rag-form>
    <div class="btn btn-success my-3"
      [ngClass]="this.loginService.loggedInUser?.enableAuthentificator ? 'btn-danger' : 'btn-success'"
      (click)="this.loginService.loggedInUser?.enableAuthentificator ?  disableAuth() : setupAuth()"><i
        class="ri-shield-check-fill"></i>{{authStatusText()}} </div>

    <div class="btn btn-success my-3 mx-2" (click)="registerPasskey()" i18n="@@layoutPasskeyRegistration">
      Passkey registrieren
    </div>


    <h3 class="my-3" i18n="@@layoutMandantSelection">Mandantenauswahl</h3>

    <div class="mandant ro" [ngClass]="router.url.startsWith('/admin') ? 'bg-primary' : ''"
      (click)="router.navigate(['/admin'])" *ngIf="this.loginService.loggedInUser?.reflactadmin">

      <div class="d-flex align-items-center px-3"><i class="ri-3x ri-shield-user-line"></i><b
          class="ms-2">Superadmin</b>
      </div>
    </div>
    <div class="mandant ro" *ngFor="let mandant of mandants"
      [ngClass]="currentMandant != undefined && !router.url.startsWith('/admin') ?( currentMandant == mandant._id.toString() ? 'bg-primary' : '') : ''"
      (click)="changeMandant(mandant._id); router.navigate(['/'])">
      <div class="d-flex align-items-center px-3"><i class="ri-building-line ri-3x"></i><b
          class="ms-2">{{mandant.name}}</b>
      </div>
    </div>


    <ng-template #authenticatorModal>
      <h3 class="text-center" i18n="@@dashboardAttention">Authentikator</h3>
      <div class="text-center">


        <div class="m-3 text-center">
          <p class="fs-5" i18n="@@dashboardDeleteQuestion">Scannen Sie diesen QR-Code mit Ihrer Authenticator-App, um
            sich immer auf diesem Weg anzumelden:</p>
          <img src="{{qrCodeImage}}" alt="" style="height: 300px;" class="m-3">
          <p class="fs-5" i18n="@@layoutEnterCurrentAuthCode">Geben Sie Ihren aktuellen Code aus Ihrer Authenticator-App
            ein</p>
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <input type="password" class="form-control" id="exampleInputPassword1" style="width: 300px;"
                [(ngModel)]="authCode" (keyup.enter)="enableAuth()">
            </div>
            <div class="col"></div>
          </div>


        </div>
      </div>
      <div class="row">
        <div class="col text-end">
          <div class="btn btn-lg btn-success text-white" i18n="@@activate" (click)="enableAuth();"><i
              class="ri-shield-check-fill"></i> Aktivieren</div>
        </div>
        <div class="col">
          <div class="btn btn-lg btn-outline-primary border-0" (click)="selfUserSettings.closeModal(); "
            i18n="@@cancel"><i class="ri-close-line"></i> Abbrechen</div>
        </div>
      </div>
    </ng-template>

  </div>
</rag-overlay-aside>