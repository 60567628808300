<div class="row">
  <div
    class="col-2"
    *ngIf="this.messageType == 'outgoing'"
  ></div>
  <div class="col-10">
    <div
      class="small px-5 text-muted "
      [ngClass]="{'text-end': this.messageType == 'outgoing'}"
    >{{dayjsHtml(this.article.IncomingTime * 1000).format('DD.MM.YYYY HH:mm')}}</div>
    <div
      class="card rounded-4 shadow border-0"
      style="cursor: pointer;"
      [ngClass]="{
        'incoming': this.messageType == 'incoming',
        'outgoing': this.messageType == 'outgoing'
      }"
      [ngStyle]="{'background-color': this.messageType == 'incoming' ? '#ffffff':'#dde9ff'}"
      (click)="this.clicked.emit(this.opened ? 0 : this.article.ArticleNumber)"
    >
      <div class="card-body p-4 overflow-auto">
        <b>#{{this.articleNumber}}</b>
        <div
          class="row"
          *ngIf="!this.opened"
        >
          <div class="col">
            <div
              class="message-body closed"
              [innerText]="this.article.Body"
            ></div>
          </div>
          <div class="col-auto">
            <ng-container *ngFor="let attachment of downloadableAttatchments; let i = index;">
              <ng-container *ngIf="i == 0">
                <a
                  [href]="getB64Href(attachment.Content, attachment.ContentType)"
                  [download]="attachment.Filename"
                  target="_blank"
                  class="me-3 fs-5 text-decoration-none text-primary"
                ><app-contenttype-icon [contentType]="attachment.ContentType"></app-contenttype-icon>
                  {{attachment.Filename}}</a>
              </ng-container>
              <ng-container *ngIf="i == 1">
                <p class="text-primary">
                  und {{downloadableAttatchments.length-1}} weitere...</p>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="this.opened && downloadableAttatchments.length > 0">
          <div class="row">
            <ng-container *ngFor="let attachment of downloadableAttatchments; let i = index;">
              <div class="col-auto">
                <a
                  [href]="getB64Href(attachment.Content, attachment.ContentType)"
                  [download]="attachment.Filename"
                  target="_blank"
                  class="me-3 fs-5 text-decoration-none text-primary"
                ><app-contenttype-icon [contentType]="attachment.ContentType"></app-contenttype-icon>
                  {{attachment.Filename}}</a>
              </div>
            </ng-container>
          </div>
          <hr
            [ngClass]="{'border-white': this.messageType == 'outgoing','border-primary': this.messageType == 'incoming'}"
          >
        </ng-container>
        <div
          *ngIf="this.opened"
          class="message-body"
          [innerHtml]="this.body"
        ></div>
        <div
          *ngIf="!this.opened"
          class="small text-primary"
        >
          Details aufklappen
        </div>
      </div>
    </div>
  </div>
  <div
    class="col-2"
    *ngIf="this.messageType == 'incoming'"
  ></div>
</div>