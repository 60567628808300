<button
  class="btn btn-primary col my-3"
  (click)="open();"
>
  {{newUserText}}
</button>
<div class="card border-0 shadow">
  <div class="card-body">
    <lib-rag-datasupply-table
      #table
      [columns]="columns"
      [service]="service"
      [selectable]="true"
      [translatedLabels]="dsTranslatedLabels"
      (onSelect)="overlay.setSelected($event);"
    ></lib-rag-datasupply-table>
  </div>
</div>
<rag-overlay-aside #overlay>
  <div
    header
    *ngIf="overlay.selectedObject"
  >
    <h1 i18n="@@globalEdit">Bearbeiten</h1>
  </div>
  <div *ngIf="overlay.selectedObject">
    <rag-form
      #form
      [data]="overlay.selectedObject"
      [formFields]="formFields"
      [hideDeleteButton]="!deleteButton"
      [translatedLabels]="translatedLabels"
      (submit)="updateUser($event)"
      (delete)="deleteUser($event)"
    ></rag-form>
  </div>
</rag-overlay-aside>

<rag-overlay-aside #overlayNew>
  <div
    header
    *ngIf="overlayNew.selectedObject"
  >
    <h1 i18n="@@globalCreate">Erstellen</h1>
  </div>
  <div *ngIf="overlayNew.selectedObject">
    <rag-form
      type="create"
      [data]="formFieldCreatorData"
      [formFields]="formFieldsCreator"
      [translatedLabels]="translatedLabels"
      (submit)="createUser($event)"
    ></rag-form>
  </div>
</rag-overlay-aside>

<ng-template
  #excludeCrmIds
  let-data="data"
>
  <p i18n="@@excludeVkcs">Service deaktivieren:</p>
  <ng-container *ngFor="let vkc of this.vkcs">
    <div
      class="btn my-1 me-2"
      [ngClass]="{
          'btn-primary': data.excludeCrmIds.includes(vkc.crm_id),
          'btn-light': !data.excludeCrmIds.includes(vkc.crm_id)
        }"
      (click)="this.onToggleVkc(data, vkc.crm_id)"
    >{{vkc.name}}</div>
  </ng-container>
</ng-template>