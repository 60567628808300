<div
  class="btn btn-primary my-3"
  (click)="open()"
  i18n="@@createMandant"
>Neuer Mandant</div>
<div class="card border-0 shadow">
  <div class="card-body">
    <lib-rag-datasupply-table
      #table
      [service]="frontendService"
      [columns]="[
        { fieldName: 'name' },
        { fieldName: 'tiles', template: tilestpl}
      ]"
      [translatedLabels]="dsTranslatedLabels"
      [selectable]="true"
      (onSelect)="overlay.setSelected($event)"
    ></lib-rag-datasupply-table>
  </div>
</div>

<rag-overlay-aside #overlay>
  <div
    header
    *ngIf="overlay.selectedObject"
  >
    <h1 i18n="@@globalEdit">Bearbeiten</h1>
  </div>
  <div *ngIf="overlay.selectedObject">
    <rag-form
      #form
      type="edit"
      [data]="overlay.selectedObject"
      [formFields]="[
        { fieldName: 'name', type: 'text', required: true},
        { fieldName: 'short', type: 'text', required: true},
        { fieldName: 'tiles', type: 'multiselect', required: true, options: {
          selectValues: this.tiles, bindLabel: 'name', bindValue: '_id'
        }}
      ]"
      [translatedLabels]="translatedLabels"
      (submit)="updateMandant($event)"
      (delete)="deleteMandant($event)"
    ></rag-form>
  </div>
</rag-overlay-aside>

<rag-overlay-aside #overlayNew>
  <div
    header
    *ngIf="overlayNew.selectedObject"
  >
    <h1 i18n="@@globalCreate">Erstellen</h1>
  </div>
  <div *ngIf="overlayNew.selectedObject">
    <div class="form-floating mb-3">
      <ng-select
        class="form-control"
        [items]="this.accounts$ | async"
        (search)="this.searchAccount($event)"
        (change)="this.selectAccount($event)"
        bindValue="crm_id"
        bindLabel="name"
      ></ng-select>
      <label for='floatingInputGrid'>Firma suchen</label>
    </div>

    <rag-form
      #formNew
      type="create"
      [data]="{name: '', short: '', tiles: []}"
      [formFields]="[
        { fieldName: 'name', type: 'text', required: true, readonly: true},
        { fieldName: 'short', type: 'text', required: true, readonly: true},
        { fieldName: 'tiles', type: 'multiselect', required: true, options: {
          selectValues: this.tiles, bindLabel: 'name', bindValue: '_id'
        }}
      ]"
      [translatedLabels]="translatedLabels"
      (submit)="createMandant($event)"
    >
    </rag-form>

  </div>
</rag-overlay-aside>

<ng-template
  #tilestpl
  let-data="data"
>
  <span
    class="badge bg-primary m-1"
    *ngFor="let tile of data.tiles"
  >{{getTileById(tile)?.name}}</span>
</ng-template>