import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxBsonService } from '@reflact/ngx-bson';
import { AccountSearchRequest, AccountSearchResponse, CreateMandantRequest, DefaultResponse, EditMandantRequest, FrontendMandant, ListMandantsResponse, MandantIdRequest } from '@reflact/reflactportal';
import { ObjectId } from 'bson';
import { LoginService } from './login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MandantService {
  public mandants: FrontendMandant[] = [];

  constructor(public http: HttpClient, public bsonService: NgxBsonService, public loginService: LoginService) { }

  public async getMandants(): Promise<FrontendMandant[]> {
    const res = await this.bsonService.getBson<ListMandantsResponse>("/api/mandant/list");
    if (res.status == "ok") {
      this.mandants = res.mandants;
      return res.mandants;
    }
    return [];
  }

  public async editManant(mandantId: ObjectId, name: string, tiles: ObjectId[]): Promise<DefaultResponse> {
    const req: EditMandantRequest = {
      "mandantId": mandantId,
      "name": name,
      "tiles": tiles
    }
    const res = await this.bsonService.postBson<EditMandantRequest, DefaultResponse>("/api/mandant/edit", req);
    return res;
  }

  public async createMandant(name: string, short: string, tiles: ObjectId[]): Promise<DefaultResponse> {
    const req: CreateMandantRequest = {
      "name": name,
      "short": short,
      "tiles": tiles
    }
    const res = await this.bsonService.postBson<CreateMandantRequest, DefaultResponse>("/api/mandant/create", req);
    return res;
  }

  public async deleteMandant(mandantId: ObjectId): Promise<DefaultResponse> {
    const req: MandantIdRequest = {
      "mandantId": mandantId
    }
    const res = await this.bsonService.postBson<MandantIdRequest, DefaultResponse>("/api/mandant/delete", req);
    return res;
  }

  public findMandant(id: ObjectId) {
    return this.mandants.find(m => { return m._id.equals(id) });
  }

  public async searchAccount(search: string) {
    const req: AccountSearchRequest = {
      search
    }
    return await this.bsonService.postBson<AccountSearchRequest, AccountSearchResponse>("/api/myreflact/accountsearch", req);
  }
}