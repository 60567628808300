import { Component, Input, OnInit } from '@angular/core';

export type ContentType =
  "none" |
  "image/bmp" |
  "image/gif" |
  "image/vnd.microsoft.icon" |
  "image/webp" |
  "image/svg+xml" |
  "image/png" |
  "image/jpeg" |

  "text/csv" |
  "text/plain" |

  "application/json" |
  "application/msword" |
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" |
  "application/pdf" |

  "application/vnd.ms-powerpoint" |
  "application/vnd.openxmlformats-officedocument.presentationml.presentation" |

  "application/vnd.ms-excel" |
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" |

  "application/zip" |

  "audio/wav" |
  "audio/webm" |
  "audio/mpeg" |
  "application/x-directory" |
  "video/webm" |
  "video/mp4" |
  string;

@Component({
  selector: 'app-contenttype-icon',
  templateUrl: './contenttype-icon.component.html',
  styleUrls: ['./contenttype-icon.component.css']
})
export class ContenttypeIconComponent implements OnInit {

  @Input() contentType: ContentType = "none";
  @Input() displayType: 'line' | 'fill' = 'line';
  public iconClass = ""

  public map = new Map([
    ["none", "file"],

    ["image", "image"],
    ["image/bmp", "image"],
    ["image/gif", "file-gif"],
    ["image/vnd.microsoft.icon", "image"],
    ["image/webp", "image"],
    ["image/svg+xml", "image"],
    ["image/png", "image"],
    ["image/jpeg", "image"],

    ["text", "file-text"],
    ["text/csv", "file-text"],
    ["text/plain", "file-text"],

    ["application", "file-text"],
    ["application/json", "file-code"],
    ["application/msword", "file-word"],
    ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "file-word"],
    ["application/pdf", "file-pdf"],
    ["application/vnd.ms-powerpoint", "file-ppt"],
    ["application/vnd.openxmlformats-officedocument.presentationml.presentation", "file-ppt"],
    ["application/vnd.ms-excel", "file-excel"],
    ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "file-excel"],
    ["application/zip", "file-zip"],

    ["audio", "file-music"],
    ["audio/wav", "file-music"],
    ["audio/webm", "file-music"],
    ["audio/mpeg", "file-music"],

    ["video", "film"],
    ["video/webm", "film"],
    ["video/mp4", "film"],

    ["application/x-directory", "folder"]
  ])

  constructor() { }

  public getTypedIcon() {

    try {

      let icon = this.map.get(this.contentType);
      if (icon) return "ri-" + icon + "-" + this.displayType;

      let type = this.contentType.split("/")[0];
      icon = this.map.get(type);
      if (icon) return "ri-" + icon + "-" + this.displayType;
      return "ri-file-" + this.displayType;
    } catch (e) {
      return "ri-file-" + this.displayType;
    }
  }

  ngOnInit(): void {
  }

}
