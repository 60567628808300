<div
  class="btn btn-primary my-3"
  (click)="open()"
  i18n="@@createTile"
>
  Kachel erstellen
</div>

<ng-template
  #shortImg
  let-data="data"
>
  {{data.img|shorty: 30}}
</ng-template>
<ng-template
  #shortUrl
  let-data="data"
>
  {{data.url|shorty: 30}}
</ng-template>
<ng-template
  #showStandard
  let-data="data"
>
  <i
    class="ri-close-line text-danger"
    *ngIf="!data.showByDefault"
  ></i>
  <i
    class="ri-check-line text-success"
    *ngIf="data.showByDefault"
  ></i>
</ng-template>

<ng-template
  #colorPicker
  let-data="data"
>
  <div class="form-group">
    <label
      class="form-label"
      for="color"
      i18n="@@tilesAdminColor"
    >Akzentfarbe</label>
    <span class="text-danger">*</span>
    <br>
    <input
      id="color"
      [(ngModel)]="data.color"
      type="color"
    >
  </div>
</ng-template>

<ng-template
  #permissionsTpl
  let-data="data"
>
  <span
    class="badge bg-primary m-1"
    *ngFor="let permission of data.permissions"
  >{{permission}}</span>
</ng-template>

<div class="card border-0 shadow">
  <div class="card-body">
    <lib-rag-datasupply-table
      #table
      [service]="frontendService"
      [columns]="[
        { fieldName: 'name'},
        { fieldName: 'showByDefault', template:showStandard},
        { fieldName: 'text'},
        { fieldName: 'subtext'},
        { fieldName: 'url', template: shortUrl},
        { fieldName: 'permissions', template: permissionsTpl}
      ]"
      [translatedLabels]="dsTranslatedLabels"
      [selectable]="true"
      (onSelect)="overlay.setSelected($event)"
    ></lib-rag-datasupply-table>
  </div>
</div>

<rag-overlay-aside #overlay>
  <div
    header
    *ngIf="overlay.selectedObject"
  >
    <h1 i18n="@@globalEdit">Bearbeiten</h1>
  </div>
  <div *ngIf="overlay.selectedObject">
    <rag-form
      [data]="overlay.selectedObject"
      [formFields]="[
        { fieldName: 'name', type: 'text', required: true  },
        { fieldName: 'showByDefault', type: 'switch'},
        { fieldName: 'openInNewTab', type: 'switch'},
        { fieldName: 'permissions', type: 'multiselect', required: true, options: { selectValues: this.permissions}  },
        { fieldName: 'text', type: 'text', required: true  },
        { fieldName: 'subtext', type: 'text', required: true  },
        { fieldName: 'url', type: 'text', required: true  },
        { fieldName: 'img', type: 'text', required: true  },
        { fieldName: 'icon', type: 'text', required: true },
        { fieldName: 'color', required: true, type: 'template', options: {template: colorPicker} }
      ]"
      [translatedLabels]="this.translatedLabels"
      (submit)="updateTile($event)"
      (delete)="deleteTile($event)"
    >
    </rag-form>
  </div>
</rag-overlay-aside>

<rag-overlay-aside #overlayNew>
  <div
    header
    *ngIf="overlayNew.selectedObject"
  >
    <h1 i18n="@@globalCreate">Erstellen</h1>
  </div>
  <div *ngIf="overlayNew.selectedObject">
    <rag-form
      type="create"
      [data]="{name: '', showByDefault: false, openInNewTab: true, text: '', subtext: '', url: '', img: ''}"
      [formFields]="[
        { fieldName: 'name', type: 'text', required: true },
        { fieldName: 'showByDefault', type: 'switch'},
        { fieldName: 'openInNewTab', type: 'switch'},
        { fieldName: 'permissions', type: 'multiselect', required: true, options: { selectValues: this.permissions}  },
        { fieldName: 'text', type: 'text', required: true  },
        { fieldName: 'subtext', type: 'text', required: true  },
        { fieldName: 'url', type: 'text', required: true  },
        { fieldName: 'img', type: 'text', required: true  },
        { fieldName: 'icon', type: 'text', required: true },
        { fieldName: 'color', required: true, type: 'template', options: {template: colorPicker} }
      ]"
      [translatedLabels]="translatedLabels"
      (submit)="createTile($event)"
    >
    </rag-form>
  </div>
</rag-overlay-aside>