<div
  class="card shadow border-0 m-3"
  *ngIf="this.serviceStatementVkcs.length > 0"
>
  <div
    class="card-header bg-white"
    i18n="@@welcomeStatements"
  >Kontoauszüge</div>
  <div class="card-body">
    <div class="container">
      <div
        *ngFor="let vkc of this.serviceStatementVkcs"
        class="row mb-1"
      >
        <div class="col">{{vkc.name}}</div>
        <div class="col text-end">
          <button
            class="btn btn-primary btn-sm ri ri-download-2-line"
            (click)="this.faqService.downloadServiceStatement(vkc.crm_id)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="m-3">
  <app-sharing-space></app-sharing-space>
</div>