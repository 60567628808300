import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OverlayAsideComponent, OverlayService } from '@reflact/ngx-layout';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { CreateServiceQuestionRequest, OTRS_QID, ServiceQuestion, ServiceQuestionWithOtrsInfo, Vkc } from '@reflact/reflactportal';
import { Editor, Toolbar } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { openOverlayAfterDataLoad } from 'src/app/shared/components/user-management/user-management.component';
import { FaqService } from 'src/app/shared/services/faq.service';
import { LoginService } from 'src/app/shared/services/login.service';


@Component({
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
  providers: [RagDatasupplyFrontendService]
})
export class ServiceComponent implements OnInit, OnDestroy {
  public contingents: Vkc[] = [];
  public contingentMap: Map<string, Vkc> = new Map();
  public serviceQuestions: ServiceQuestion[] = []
  public ortsTicketMap: Map<string, ServiceQuestionWithOtrsInfo> = new Map()
  public otrsIds: { key: string, value: number }[] = []
  public otrsIdsMap: Map<number, string> = new Map()
  public columns: RagDatasupplyTypes.TableColumn[] = [
    { fieldName: 'type', label: 'Typ der Anfrage' },
    { fieldName: 'subject', label: 'Betreff' },
    { fieldName: 'status', label: 'Status' },
    { fieldName: 'contingetId', label: 'Kontingent' }
  ];
  public newServiceQuestion!: CreateServiceQuestionRequest;
  public openedArticle: number = 0;

  public editorInput = "";
  public editor!: Editor;
  public toolbar: Toolbar = [
    ['bold', 'italic', 'underline'],
    ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'],
    ['link', 'image'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  public placeholders: string[] = [];
  public newFormOpen: boolean = false;
  public newFormInvalid: boolean = false;
  @ViewChild("overlay") public overlay!: OverlayAsideComponent;

  constructor(public frontendService: RagDatasupplyFrontendService<ServiceQuestion>, private toastrService: ToastrService, private overlayService: OverlayService, public faqService: FaqService, public loginService: LoginService) { }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  public getPercentage(vkc: Vkc) {
    let percentage = 0;
    let minutes = 0;
    vkc.tracks.forEach(t => {
      minutes += t.minutes;
    })
    if (vkc.supportcontingent > 0) {
      percentage = minutes / vkc.supportcontingent;
    }
    return percentage * 100
  }

  public getTrackedHours(vkc: Vkc) {
    let minutes = 0;
    vkc.tracks.forEach(t => {
      minutes += t.minutes;
    })
    return minutes / 60;
  }

  async ngOnInit(): Promise<void> {
    this.editor = new Editor();
    this.otrsIds = Object.keys(OTRS_QID).map((k) => {
      this.otrsIdsMap.set((OTRS_QID as any)[k], k);
      return { key: k, value: (OTRS_QID as any)[k] }
    })
    await this.faqService.getAccountInfo();
    this.contingents = this.faqService.activeVkcs;
    console.log(this.contingents)
    this.faqService.serviceVkcs.forEach(c => {
      this.contingentMap.set(c.crm_id, c);
    })
    this.newServiceQuestion = {
      serviceQuestion: {
        contingentId: this.contingents[0]?.crm_id ?? "",
        otrsQId: this.otrsIds[0].value,
        title: "",
        body: ""
      }
    }
    this.serviceQuestions = (await this.faqService.getServiceQuestions({})).serviceQuestions
    this.frontendService.fromArray(this.serviceQuestions);
    (await this.faqService.getServiceQuestionsWithOtrsInfo({ ticketIds: this.serviceQuestions.map(sq => { return sq.otrsTicketId }) })).serviceQuestions.map(sq => {
      this.ortsTicketMap.set(sq.otrsTicketId, sq)
    })
    openOverlayAfterDataLoad(this.frontendService, this.overlayService, this.overlay, [this.overlay])

  }



  public async createServiceQuestion() {
    this.newServiceQuestion.serviceQuestion.body = this.editorInput
    const req: CreateServiceQuestionRequest = this.newServiceQuestion
    if (!this.getIfValidQuestion(req)) {
      this.newFormInvalid = true;
      return;
    }
    if (req.serviceQuestion.contingentId != undefined && req.serviceQuestion.contingentId != "") {
      let remainingTime = 9999;
      const vkc = this.faqService.activeVkcs.find(vkc => vkc.crm_id == req.serviceQuestion.contingentId);
      if (vkc) {
        remainingTime = this.getTrackedHours(vkc) * 60;
      }
      req.serviceQuestion.body += '<p>Kontingent: ' + vkc?.name + '<br>' + remainingTime + ' Min. / ' + vkc?.supportcontingent + ' Min. verbleibend</p>';
    } else {
      req.serviceQuestion.body += '<p>Kein Kontingent gewählt.</p>';
    }
    const res = await this.faqService.createServiceQuestion(req)
    if (res.status == "ok") {
      this.serviceQuestions.push(res.serviceQuestion)
      this.newServiceQuestion = {
        serviceQuestion: {
          contingentId: this.contingents[0].crm_id,
          otrsQId: this.otrsIds[0].value,
          title: "",
          body: ""
        }
      }
      this.newFormOpen = false;
    }
  }

  private getIfValidQuestion(sq: CreateServiceQuestionRequest): boolean {
    if (sq.serviceQuestion.title == "" || sq.serviceQuestion.title == undefined) return false;
    if (sq.serviceQuestion.body == "" || sq.serviceQuestion.body == undefined) return false;
    if ((sq.serviceQuestion.contingentId == "" || sq.serviceQuestion.contingentId == undefined) && this.contingents.length > 0) return false;
    if (sq.serviceQuestion.otrsQId == undefined) return false;
    return true
  }


}
