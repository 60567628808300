import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { MailManagerModule } from '@reflact/mail-manager';
import { NgxFormsModule } from '@reflact/ngx-forms';
import { NgxLayoutModule } from '@reflact/ngx-layout';
import { RagDatasupplyModule } from '@reflact/rag-datasupply';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxEditorModule } from 'ngx-editor';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContenttypeIconComponent } from './shared/components/contenttype-icon/contenttype-icon.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { ServiceMessageComponent } from './shared/components/service-message/service-message.component';
import { SimplePieComponent } from './shared/components/simple-pie/simple-pie.component';
import { UserManagementComponent } from './shared/components/user-management/user-management.component';
import { VkcDisplayComponent } from './shared/components/vkc-display/vkc-display.component';
import { NgVarDirective } from './shared/directives/ng-var.directive';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { FileSizePipe } from './shared/pipes/file-size.pipe';
import { NobreakPipe } from './shared/pipes/nobreak.pipe';
import { ShortyPipe } from './shared/pipes/shorty.pipe';
import { DashboardAdministrationComponent } from './views/dashboard-administration/dashboard-administration.component';
import { GlobalUserAdministrationComponent } from './views/global-user-administration/global-user-administration.component';
import { LoginComponent } from './views/login/login.component';
import { MailManagerAdministrationComponent } from './views/mail-manager-administration/mail-manager-administration.component';
import { MandantAdministrationComponent } from './views/mandant-administration/mandant-administration.component';
import { ServiceComponent } from './views/service/service.component';
import { SharingSpaceComponent } from './views/sharing-space/sharing-space.component';
import { TileAdministrationComponent } from './views/tile-administration/tile-administration.component';
import { ToolsAndTrainingsComponent } from './views/tools-and-trainings/tools-and-trainings.component';
import { UserAdministrationComponent } from './views/user-administration/user-administration.component';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { NgxAuthModule, NgxAuthService } from '@reflact/ngx-auth';
@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    ToolsAndTrainingsComponent,
    UserAdministrationComponent,
    GlobalUserAdministrationComponent,
    UserManagementComponent,
    LoginComponent,
    LayoutComponent,
    MailManagerAdministrationComponent,
    MandantAdministrationComponent,
    TileAdministrationComponent,
    DashboardAdministrationComponent,
    ServiceComponent,
    VkcDisplayComponent,
    SimplePieComponent,
    SharingSpaceComponent,
    NgVarDirective,
    ServiceMessageComponent,
    NobreakPipe,
    FileSizePipe,
    ShortyPipe,
    ContenttypeIconComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxLayoutModule,
    HttpClientModule,
    RagDatasupplyModule,
    FormsModule,
    CommonModule,
    ModalModule.forRoot(),
    MailManagerModule,
    NgSelectModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-left', preventDuplicates: true }),
    BrowserAnimationsModule,
    NgxEditorModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgxFormsModule,
    TooltipModule.forRoot(),
    NgxAuthModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    NgVarDirective
  ]
})
export class AppModule { }
