import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxAuthService } from '@reflact/ngx-auth';
import { FrontendMandant } from '@reflact/reflactportal';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public email: string = "";
  public code: string = "";
  public message = ""
  public state: 'email' | 'code' | 'mandant' = 'email';
  public mandants: FrontendMandant[] = []
  public mandant: string = "";

  constructor(public router: Router, public loginService: LoginService, private ngxAuthService: NgxAuthService) {

  }
  /*
  async ngOnInit() {
    await this.loginService.getTokenAndLogin();
    this.mandants = (await this.loginService.getMandantsForUser()).mandants;
    this.state = "selectMandant";
  }
  */

  public async login() {
    console.log("Login is Called")
    if (this.state == "email") {
      const res = await this.ngxAuthService.authenticatePasskey<string>(this.email);
      if (res.status == "ok") {
        if (res.token) {
          await this.loginWithToken(res.token);
        }
      } else {
        await this.sendCode();
        this.state = "code";
      }
    } else if (this.state == "code") {
      this.loginWithCode();
    }
  }

  public async sendCode() {
    if (this.email == "") {
      this.message = "Please enter your email";
    } else if (!this.email.includes("@")) {
      this.message = "Please enter a valid email";
    } else {

      try {
        const _res = await this.loginService.sendLoginCodeForEmail(this.email);
      } catch (e) {
        this.message = "Check your e-mail or try again in 10 minutes"
      }
      this.state = 'code';
    }
  }

  public async loginWithCode() {
    if (this.code == "") {
      this.message = "Please enter your code from the email";
    } else {
      try {
        const res = await this.loginService.loginWithCode(this.email, this.code);
        this.message = ''
        if (res?.mandant == undefined) {
          this.loginService.getTokenAndLogin();
          this.mandants = (await this.loginService.getMandantsForUser()).mandants;
          this.state = "mandant";
        } else {
          this.router.navigate([""]);
        }
      } catch (e) {
        this.message = "Check your code and try again in 10 minutes"
      }
    }
  }

  public async loginWithToken(token: string) {
    const res = await this.loginService.loginWithToken(token);
    try {
      if (res?.mandant == undefined) {
        this.loginService.getTokenAndLogin();
        this.mandants = (await this.loginService.getMandantsForUser()).mandants;
        this.state = "mandant";
      } else {
        this.router.navigate([""]);
      }
    } catch (e) {
      this.message = "Check your code and try again in 10 minutes"
    }
  }

  public async selectMandant(mandant: FrontendMandant) {
    if (mandant == undefined) return
    await this.loginService.getTokenAndLogin();
    const res = await this.loginService.selectMandant(mandant._id);
    if (res) {
      this.router.navigate([""])
    }
  }
}
