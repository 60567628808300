<app-user-management
  [columns]="[ { fieldName: '', label: 'Email', template: emailtpl},
  { fieldName: '', label: 'Name', template: nametpl},
  { fieldName: '', label: 'Permissions', template: permissionstpl}]"
  [formFields]="[
    { fieldName: 'globalUser.email', label: 'Email', type:'text', readonly: true},
    { fieldName: 'globalUser.name',label: 'Name', type:'text', readonly: true},
    { fieldName: 'permissions', label: 'Permissions', type: 'multiselect', required: true, options: { selectValues: this.permissions}  }
  ]"
  [formFieldsCreator]="[
    { fieldName: 'globalUser.email', label: 'Email', type: 'text', required: true},
    { fieldName: 'globalUser.name',label: 'Name', type: 'text', required: true},
    { fieldName: 'permissions', label: 'Permissions', type: 'multiselect', required: true, options: { selectValues: this.permissions }  }
  ]"
  [formFieldCreatorData]="{globalUser: {email: '', name: ''}, permissions: []}"
  [translatedLabels]="translatedLabels"
  [service]="frontendService"
  (updateUserEvent)="updateUser($event);"
  (createUserEvent)="createUser($event);"
  (deleteUserEvent)="deleteUser($event);"
  [userType]="'fullUser'"
  i18n-newUserText="@@newUser"
  newUserText="Neuer Nutzer"
  [deleteButton]="true"
  [enableVkcs]="true"
></app-user-management>

<ng-template
  #emailtpl
  let-data="data"
>
  {{data.globalUser.email}}
</ng-template>

<ng-template
  #nametpl
  let-data="data"
>
  {{data.globalUser.name}}
</ng-template>

<ng-template
  #permissionstpl
  let-data="data"
>
  <span
    class="badge bg-primary m-1"
    *ngFor="let permission of data.permissions"
  >{{permission}}</span>
</ng-template>