import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormComponent, convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { NgxForm } from '@reflact/ngx-forms/lib/formtypes';
import { OverlayAsideComponent, OverlayService } from '@reflact/ngx-layout';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { AccountSearchAccount, AccountSearchResponse, FrontendMandant, Tile } from '@reflact/reflactportal';
import { ObjectId } from 'bson';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { openOverlayAfterDataLoad } from 'src/app/shared/components/user-management/user-management.component';
import { MandantService } from 'src/app/shared/services/mandant.service';
import { TileService } from 'src/app/shared/services/tile.service';

@Component({
  templateUrl: './mandant-administration.component.html',
  styleUrls: ['./mandant-administration.component.scss'],
  providers: [RagDatasupplyFrontendService]
})
export class MandantAdministrationComponent implements AfterViewInit, OnInit {
  @ViewChild("table") public table!: OverlayAsideComponent;
  @ViewChild("overlay") public overlay!: OverlayAsideComponent;
  @ViewChild("overlayNew") public overlayNew!: OverlayAsideComponent;
  @ViewChild('formNew') public formNew!: FormComponent;
  public translatedLabels: NgxForm.TranslatedLabels = new Map<string, string>([
    ["name", $localize`:@@globalName:Name`],
    ["short", $localize`:@@mandantAdminShort:Kürzel`],
    ["tiles", $localize`:@@mandantAdminTiles:Kacheln`]
  ])
  public dsTranslatedLabels: RagDatasupplyTypes.TranslatedLabels[] = []
  public tiles: Tile[] = [];
  public accounts: AccountSearchAccount[] = [];
  public accounts$: Observable<AccountSearchAccount[]> = new Observable();

  constructor(public frontendService: RagDatasupplyFrontendService<FrontendMandant>, public mandantService: MandantService, private toastrService: ToastrService, private overlayService: OverlayService, public tileService: TileService) {
    this.tileService.listTiles().then(m => {
      this.tiles = m;
    });
    this.mandantService.getMandants().then(m => this.frontendService.fromArray(m));

    this.accounts$.subscribe(a => (console.log(a)));
  }


  ngOnInit(): void {
    this.dsTranslatedLabels = convertTranslatedLabelsToDatasupply(this.translatedLabels);
  }

  public searchAccount(search: any) {
    if (search.term.length > 3) {
      this.mandantService.searchAccount(search.term).then((res: AccountSearchResponse) => {
        this.accounts = res.results;
        this.accounts$ = of(this.accounts);
      });
    } else {
      this.accounts = [];
      this.accounts$ = of(this.accounts);
    }
  }

  public selectAccount(account: AccountSearchAccount) {
    this.formNew.form.control.markAsDirty()
    this.formNew.data.name = account.name;
    this.formNew.data.short = account.kundenkuerzel_c;
  }

  public async updateMandant(data: FrontendMandant) {
    const res = await this.mandantService.editManant(data._id, data.name, data.tiles);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.mandantService.getMandants().then(m => this.frontendService.fromArray(m))
  }

  public async deleteMandant(data: FrontendMandant) {
    const res = await this.mandantService.deleteMandant(data._id);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.mandantService.getMandants().then(m => this.frontendService.fromArray(m))
    this.overlay.unsetSelected();
  }

  public async createMandant(data: any) {
    console.log('createMandant', data);
    const res = await this.mandantService.createMandant(data.name, data.short, data.tiles);
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.overlayNew.unsetSelected();
    this.mandantService.getMandants().then(m => this.frontendService.fromArray(m));
  }

  ngAfterViewInit(): void {
    openOverlayAfterDataLoad(this.frontendService, this.overlayService, this.overlay, [this.overlay, this.overlayNew])
    this.overlay.opened$.subscribe(r => {
      if (!r) {
        this.table.selectedObject = undefined;
      }
    })
  }

  public getTileById(tile: any): Tile | undefined {
    return this.tiles.find(element => element._id == tile)
  }

  public open() {
    let tile: any = {
      "_id": new ObjectId(),
      "name": "",
      "short": "",
      "tiles": ""
    }
    this.overlayNew.setSelected(tile)
  }
}