import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ArticleResponse, Attachment } from '@reflact/reflactportal';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-service-message',
  templateUrl: './service-message.component.html',
  styleUrls: ['./service-message.component.scss']
})
export class ServiceMessageComponent implements OnInit {
  @Input({ required: true }) public messageType!: 'incoming' | 'outgoing';
  @Input({ required: true }) public article!: ArticleResponse;
  @Input({ required: true }) public articleNumber!: number;
  @Input({ required: true }) public opened: boolean = false;
  @Output() public clicked: EventEmitter<number> = new EventEmitter();
  public dayjsHtml = dayjs;
  public body: SafeHtml = "";
  public downloadableAttatchments: Attachment[] = []

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    let tempBody: string = "";
    this.downloadableAttatchments = this.article.Attachment.filter(a => a.Disposition == 'attachment')
    this.article.Attachment.filter(a => {
      return a.Disposition == 'inline' && a.ContentType.includes("text/");
    }).forEach(attatchment => {
      //TODO: Image zeug muss mit b64 überschrieben werden, um bilder darzustellen
      let tempContent = attatchment.Content;
      if (attatchment.ContentType.match("image")) {
        tempContent = `<img src="data:image/png;base64, ${attatchment.Content}">`
      } else if (attatchment.ContentType.match("utf-8")) {
        tempContent = atob(tempContent);
        tempContent = decodeURIComponent(escape(tempContent));
      } else {
        tempContent = atob(tempContent);
      }
      tempBody += tempContent;
    })
    tempBody = this.replaceImgWithDecodedB64(tempBody, this.article);
    this.body = this.sanitizer.bypassSecurityTrustHtml(tempBody)
  }


  replaceImgWithDecodedB64(body: string, article: ArticleResponse): string {
    article.Attachment.filter(attachment => attachment.ContentID != "").forEach(attachment => {
      body = body.replace('cid:' + attachment.ContentID.substring(1, attachment.ContentID.length - 1), 'data:image/jpeg;base64, ' + attachment.Content)
    })
    return body;
  }

  public getB64Href(b64: string, contentType: string) {
    return 'data:' + contentType.split(';')[0] + ';base64,' + b64;
  }
}
