import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { NgxForm } from '@reflact/ngx-forms/lib/formtypes';
import { OverlayAsideComponent, OverlayService } from '@reflact/ngx-layout';
import { RagDatasupplyFrontendService, RagDatasupplyTableComponent, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { AllUserType, FullUser, GlobalFrontendUser, Vkc } from '@reflact/reflactportal';
import { ObjectId } from 'bson';
import { FaqService } from '../../services/faq.service';

export function openOverlayAfterDataLoad(frontendService: RagDatasupplyFrontendService<any>, overlayService: OverlayService, overlay: OverlayAsideComponent, overlays: OverlayAsideComponent[]) {
  frontendService.results$.subscribe(r => {
    if (r && r.length > 0 && overlayService.get("selected")) {
      if (r.find(u => u._id.toString() == overlayService.get("selected")) == undefined) {
        overlays.forEach(o => o.unsetSelected())
        return;
      }
      overlay.setSelected(r.find(u => u._id.toString() == overlayService.get("selected")))
    }
  })
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  providers: [RagDatasupplyFrontendService]
})
export class UserManagementComponent<T extends AllUserType> implements AfterViewInit, OnInit {
  @Input({ required: true }) public service!: RagDatasupplyFrontendService<T>;
  @Input({ required: true }) public columns!: RagDatasupplyTypes.TableColumn[];
  @Input({ required: true }) public formFields!: NgxForm.FormField[];
  @Input({ required: true }) public formFieldsCreator!: NgxForm.FormField[];
  @Input({ required: true }) public formFieldCreatorData!: Object;
  @Input({ required: true }) public userType!: "fullUser" | "globalFrontendUser";
  @Input({ required: true }) public newUserText!: string;
  @Input({ required: true }) public deleteButton!: boolean;
  @Input({ required: true }) public translatedLabels!: NgxForm.TranslatedLabels
  @Input() public enableVkcs: boolean = false;
  @ViewChild("overlay") public overlay!: OverlayAsideComponent;
  @ViewChild("table") public table!: RagDatasupplyTableComponent<T>;
  @ViewChild("overlayNew") public overlayNew!: OverlayAsideComponent;
  @ViewChild("excludeCrmIds") public excludeCrmIds: any;
  @Output() public updateUserEvent = new EventEmitter<T>();
  @Output() public createUserEvent = new EventEmitter<T>();
  @Output() public deleteUserEvent = new EventEmitter<T>();
  public vkcs: Vkc[] = [];
  public dsTranslatedLabels: RagDatasupplyTypes.TranslatedLabels[] = []

  constructor(private overlayService: OverlayService, private faqService: FaqService) {
    this.faqService.getAccountInfo().then(r => {
      this.vkcs = this.faqService.serviceVkcs;
      if (this.enableVkcs && this.vkcs && this.vkcs.length > 1) {
        this.formFields.push({
          fieldName: 'excludeCrmIds', type: 'template', options: {
            template: this.excludeCrmIds
          }
        });
      }
    })
  }


  ngOnInit(): void {
    this.dsTranslatedLabels = convertTranslatedLabelsToDatasupply(this.translatedLabels)
  }

  ngAfterViewInit(): void {
    openOverlayAfterDataLoad(this.service, this.overlayService, this.overlay, [this.overlay, this.overlayNew])
    this.overlay.opened$.subscribe(r => {
      if (!r) {
        this.table.selectedObject = undefined;
      }
    })
  }

  public updateUser(event: any) {
    this.updateUserEvent.emit(event)
  }

  public createUser(event: any) {
    this.createUserEvent.emit(event);
  }

  public deleteUser(event: any) {
    this.deleteUserEvent.emit(event);
  }

  public open() {
    if (this.userType == "fullUser") {
      let user: FullUser = {
        "_id": new ObjectId(),
        "globalUserId": new ObjectId,
        "permissions": [],
        "excludeCrmIds": [],
        "globalUser": {
          "_id": new ObjectId(),
          "email": "",
          "name": "",
          "reflactadmin": false,
          "enableAuthentificator": false
        }
      };
      this.overlayNew.setSelected(user)
    } else if (this.userType == "globalFrontendUser") {
      let user: GlobalFrontendUser = {
        "_id": new ObjectId(),
        "email": "",
        "name": "",
        "reflactadmin": false,
        "enableAuthentificator": false
      }
      this.overlayNew.setSelected(user)
    }
  }

  public onToggleVkc(data: FullUser, crmId: string) {
    const index = data.excludeCrmIds.indexOf(crmId);
    if (index >= 0) {
      data.excludeCrmIds.splice(index, 1);
    } else {
      data.excludeCrmIds.push(crmId);
    }
    this.updateUserEvent.emit(data as any);
  }
}