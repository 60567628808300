import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import { LoginService } from './shared/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'reflactportal-app';
  constructor(private loginService: LoginService) {
  }

  async ngOnInit(): Promise<void> {
    dayjs.extend(localeData);
    if (this.loginService.getApplicationLanguage() == "de") {
      await import("dayjs/locale/de.js");
      dayjs.locale('de')
    }
  }
}
