import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { DefaultResponse, PortalJwtPayload } from '@reflact/reflactportal';
import { LoginService } from '../services/login.service';

export const mandantAdminGuard: CanActivateFn = async (route, state) => {
  const loginService: LoginService = inject(LoginService);
  let res: DefaultResponse & Partial<PortalJwtPayload> = (await loginService.getTokenAndLogin())
  if (res.status == "ok") {
    if (res.mandant == undefined || res.mandantUser == undefined) {
      return false;
    } else if (res.mandantUser.permissions.indexOf("admin") > -1) {
      return true
    }
  }
  return false;
};
