import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxBsonService } from '@reflact/ngx-bson';
import { CreateUserAsReflactAdminRequest, CreateUserRequest, DefaultResponse, DeleteUserAsReflactAdminRequest, DeleteUserRequest, EditGlobalUserRequest, EditMyGlobalUserRequest, EditUserRequest, FullUser, GlobalFrontendUser, ListGlobalUsersResponse, ListUsersResponse, Permissions } from '@reflact/reflactportal';
import { ObjectId, serialize } from 'bson';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient, public bsonService: NgxBsonService, public loginService: LoginService) {

  }




  public async getMandantUsers(): Promise<FullUser[]> {
    const res = await this.bsonService.getBson<ListUsersResponse>("/api/user/list");
    if (res.status == "ok") {
      return res.users;
    }
    return [];
  }

  public async getGlobalUsers(): Promise<GlobalFrontendUser[]> {
    const res = await this.bsonService.getBson<ListGlobalUsersResponse>("/api/user/listGlobal");
    if (res.status == "ok") {
      return res.users;
    }
    return [];
  }

  public async createUser(email: string, name: string, permissions: Permissions[], excludeCrmIds: string[]): Promise<DefaultResponse> {
    const req: CreateUserRequest = {
      email,
      name,
      permissions,
      excludeCrmIds
    }
    const res = this.bsonService.postBson<CreateUserRequest, DefaultResponse>("/api/user/create", req);
    return res;
  }

  public async createUserAsReflactAdmin(email: string, name: string, permissions: Permissions[], mandantId: ObjectId, reflactadmin: boolean, excludeCrmIds: string[]): Promise<DefaultResponse> {
    const req: CreateUserAsReflactAdminRequest = {
      email,
      name,
      permissions,
      mandantId,
      reflactadmin,
      excludeCrmIds
    }
    const res = await this.bsonService.postBson<CreateUserAsReflactAdminRequest, DefaultResponse>("/api/user/createAsReflactAdmin", req);
    return res;
  }

  public async deleteMandantUser(userId: ObjectId): Promise<DefaultResponse> {
    const req: DeleteUserRequest = {
      "userId": userId
    }
    const res = await this.bsonService.postBson<DeleteUserRequest, DefaultResponse>("/api/user/delete", req);
    if (userId.toString() == this.loginService.loggedInUser?._id.toString()) {
      await this.loginService.refreshToken();
    }
    return res
  }

  public async deleteMandantUserAsReflactAdmin(userId: ObjectId, mandantId: ObjectId): Promise<DefaultResponse> {
    const req: DeleteUserAsReflactAdminRequest = {
      userId,
      mandantId
    }
    const res = await this.bsonService.postBson<DeleteUserAsReflactAdminRequest, DefaultResponse>("/api/user/deleteAsReflactAdmin", req);
    if (userId.toString() == this.loginService.loggedInUser?._id.toString()) {
      await this.loginService.refreshToken();
    }
    return res;
  }

  public async editMandantUser(userId: ObjectId, permissions: Permissions[], excludeCrmIds: string[]) {
    const req: EditUserRequest = {
      userId,
      permissions,
      excludeCrmIds
    }
    const res = this.bsonService.postBson<EditUserRequest, DefaultResponse>("/api/user/edit", req);
    if (userId.toString() == this.loginService.loggedInUser?._id.toString()) {
      await this.loginService.refreshToken();
    }
    return res;
  }

  public async editMyGlobalUser(email: string, name: string) {
    const req: EditMyGlobalUserRequest = {
      email,
      name
    }
    const res = await this.bsonService.postBson<EditMyGlobalUserRequest, DefaultResponse>("/api/user/editMyGlobal", req);
    return res;
  }

  public async editGlobalUser(_id: ObjectId, email: string, enableAuthentificator: boolean, name: string, reflactadmin: boolean) {
    const req: EditGlobalUserRequest = {
      _id,
      email,
      enableAuthentificator,
      name,
      reflactadmin
    }
    const res = await this.bsonService.postBson<EditGlobalUserRequest, DefaultResponse>("/api/user/editGlobal", req);
    if (_id.toString() == this.loginService.loggedInUser?._id.toString()) {
      await this.loginService.refreshToken();
    }
    return res;
  }

  public transformToBson(body: any) {
    const buffer = serialize(body)
    return new Blob([buffer]);
  }
}
