import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorty'
})
export class ShortyPipe implements PipeTransform {

  transform(value: string, length: number = 16): string {
    return value.length > length ? value.slice(0, length) + "..." : value;
  }

}
