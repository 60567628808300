import { Component } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  templateUrl: './mail-manager-administration.component.html',
  styleUrls: ['./mail-manager-administration.component.scss']
})
export class MailManagerAdministrationComponent {


  constructor(public loginService: LoginService) {

  }

}
