import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { DefaultResponse, PortalJwtPayload } from '@reflact/reflactportal';
import { LoginService } from '../services/login.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const loginService: LoginService = inject(LoginService);
  let res: DefaultResponse & Partial<PortalJwtPayload> = (await loginService.getTokenAndLogin())
  if (res.mandant == null && res.globalUser?.reflactadmin) {
    router.navigate(["admin"])
  }

  if (res.status == "ok") {
    return true
  }

  router.navigate(["login"])
  return false;
};
