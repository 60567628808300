import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxBsonService } from '@reflact/ngx-bson';
import { CreateServiceQuestionRequest, CreateServiceQuestionResponse, GetAccountInfoResponse, GetServiceQuestionOtrsInfoRequest, GetServiceQuestionOtrsInfoResponse, GetServiceQuestionsRequest, GetServiceQuestionsResponse, Vkc } from '@reflact/reflactportal';
import * as dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  public activeVkcs: Vkc[] = [];
  public serviceVkcs: Vkc[] = [];
  public serviceStatementVkcs: Vkc[] = [];

  constructor(public bsonService: NgxBsonService, private loginService: LoginService, private httpClient: HttpClient) {
  }

  public async createServiceQuestion(createServiceQuestionRequest: CreateServiceQuestionRequest): Promise<CreateServiceQuestionResponse> {
    const res = await this.bsonService.postBson<CreateServiceQuestionRequest, CreateServiceQuestionResponse>("/api/otrs/createticket", createServiceQuestionRequest);
    return res;
  }

  public async getServiceQuestions(getServiceQuestionsRequest: GetServiceQuestionsRequest): Promise<GetServiceQuestionsResponse> {
    const res = await this.bsonService.postBson<GetServiceQuestionsRequest, GetServiceQuestionsResponse>("/api/otrs/getservicequestions", getServiceQuestionsRequest);
    return res;
  }

  public async getServiceQuestionsWithOtrsInfo(getServiceQuestionOtrsInfoRequest: GetServiceQuestionOtrsInfoRequest): Promise<GetServiceQuestionOtrsInfoResponse> {
    const res = await this.bsonService.postBson<GetServiceQuestionOtrsInfoRequest, GetServiceQuestionOtrsInfoResponse>("/api/otrs/getservicequestionswithotrsinfo", getServiceQuestionOtrsInfoRequest);
    res.serviceQuestions.forEach(sq => sq.ticket.Article.reverse())
    return res
  }

  public async getAccountInfo(): Promise<GetAccountInfoResponse> {
    const res = await this.bsonService.getBson<GetAccountInfoResponse>("/api/myreflact/accountinfo");
    this.serviceStatementVkcs = res.accountInfo.vkcs.filter(vkc => (vkc.istrackable_c && vkc.istrackable_c == true) || dayjs().subtract(2, 'year').isBefore(vkc.date_closed));
    this.serviceVkcs = res.accountInfo.vkcs.filter(vkc => vkc.supportcontingent > 0 && dayjs().isBefore(vkc.supportenddate));
    this.activeVkcs = this.serviceVkcs.filter(vkc => !this.loginService.loggedInMandantUser?.excludeCrmIds.includes(vkc.crm_id));
    return res;
  }

  public downloadServiceStatement(crmId: string) {
    this.httpClient.get('/api/myreflact/serviceStatement/' + crmId, { responseType: 'text' }).subscribe(csv => {
      saveAs(new Blob([csv], { type: "text/csv;charset=utf-8" }), "products.csv");
    });
  }
}
