import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { adminGuard } from './shared/guards/admin.guard';
import { authGuard } from './shared/guards/auth.guard';
import { mandantAdminGuard } from './shared/guards/mandant-admin.guard';
import { DashboardAdministrationComponent } from './views/dashboard-administration/dashboard-administration.component';
import { GlobalUserAdministrationComponent } from './views/global-user-administration/global-user-administration.component';
import { LoginComponent } from './views/login/login.component';
import { MailManagerAdministrationComponent } from './views/mail-manager-administration/mail-manager-administration.component';
import { MandantAdministrationComponent } from './views/mandant-administration/mandant-administration.component';
import { ServiceComponent } from './views/service/service.component';
import { SharingSpaceComponent } from './views/sharing-space/sharing-space.component';
import { TileAdministrationComponent } from './views/tile-administration/tile-administration.component';
import { ToolsAndTrainingsComponent } from './views/tools-and-trainings/tools-and-trainings.component';
import { UserAdministrationComponent } from './views/user-administration/user-administration.component';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { externalLoginGuard } from './shared/guards/external-login.guard';

const routes: Routes = [
  {
    path: 'admin',
    pathMatch: "full",
    redirectTo: '/admin/dashboard'
  },
  {
    path: 'admin',
    component: LayoutComponent,
    canActivateChild: [adminGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardAdministrationComponent
      },
      {
        path: 'tiles',
        component: TileAdministrationComponent
      },
      {
        path: 'mandants',
        component: MandantAdministrationComponent
      },
      {
        path: 'emails',
        component: MailManagerAdministrationComponent
      },
      {
        path: 'users',
        component: GlobalUserAdministrationComponent
      },

    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'portal'
  },
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [authGuard],
    children: [
      {
        path: 'home',
        component: WelcomeComponent
      },
      {
        path: 'portal',
        component: ToolsAndTrainingsComponent
      },
      {
        path: 'sharingspace',
        component: SharingSpaceComponent
      },
      {
        path: 'service',
        component: ServiceComponent
      },
      {
        canActivate: [mandantAdminGuard],
        path: 'users',
        component: UserAdministrationComponent
      },


    ]
  },
  {
    path: 'externallogin/:token',
    canActivate: [externalLoginGuard],
    component: LoginComponent
  },
  {
    path: 'externallogin/:token/:target',
    canActivate: [externalLoginGuard],
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function getRoutes() {
  return routes
}
