import { Component, OnInit, ViewChild } from '@angular/core';
import { convertTranslatedLabelsToDatasupply } from '@reflact/ngx-forms';
import { NgxForm } from '@reflact/ngx-forms/lib/formtypes';
import { OverlayAsideComponent, OverlayService } from '@reflact/ngx-layout';
import { RagDatasupplyFrontendService, RagDatasupplyTableComponent, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import { Permissions, Tile } from '@reflact/reflactportal';
import { ObjectId } from 'bson';
import { ToastrService } from 'ngx-toastr';
import { MandantService } from 'src/app/shared/services/mandant.service';
import { TileService } from 'src/app/shared/services/tile.service';

@Component({
  templateUrl: './tile-administration.component.html',
  styleUrls: ['./tile-administration.component.scss'],
  providers: [RagDatasupplyFrontendService]
})
export class TileAdministrationComponent implements OnInit {

  @ViewChild("overlay") public overlay!: OverlayAsideComponent;
  @ViewChild("overlayNew") public overlayNew!: OverlayAsideComponent;
  @ViewChild("table") public table!: RagDatasupplyTableComponent<Tile>;
  public translatedLabels: NgxForm.TranslatedLabels = new Map<string, string>([
    ["name", $localize`:@@globalName:Name`],
    ["showByDefault", $localize`:@@tilesAdminShowByDefault:Standard sichtbar`],
    ["openInNewTab", $localize`:@@tilesAdminOpenInNewTab:Öffnen in neuem Tab`],
    ["text", $localize`:@@tilesAdminText:Überschrift`],
    ["subtext", $localize`:@@tilesAdminSubtext:Subtext`],
    ["url", $localize`:@@tilesAdminURL:URL`],
    ["img", $localize`:@@tilesAdminImgURL:Bild-URL`],
    ["icon", $localize`:@@tilesAdminIconURL:Icon-URL`],
    ["permissions", $localize`:@@globalUserAdminPermissions:Berechtigungen`],
  ])
  public permissions: Permissions[] = ["admin", "service", "kitools-admin"];
  public dsTranslatedLabels: RagDatasupplyTypes.TranslatedLabels[] = []
  constructor(public frontendService: RagDatasupplyFrontendService<Tile>, private mandantService: MandantService, private tileService: TileService, private toastrService: ToastrService, private overlayService: OverlayService) {
    this.tileService.listTiles().then(m => this.frontendService.fromArray(m))
  }


  ngOnInit(): void {
    this.dsTranslatedLabels = convertTranslatedLabelsToDatasupply(this.translatedLabels)
  }

  public async createTile(data: Tile) {
    const res = await this.tileService.createTile(data.name, data.showByDefault, data.text, data.subtext, data.url, data.img, data.openInNewTab, data.color, data.icon, data.permissions ?? [])
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.overlayNew.unsetSelected();
    this.tileService.listTiles().then(m => this.frontendService.fromArray(m))
  }

  public async updateTile(data: Tile) {
    const res = await this.tileService.editTile(data)
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.tileService.listTiles().then(m => this.frontendService.fromArray(m))
  }

  public async deleteTile(data: Tile) {
    const res = await this.tileService.deleteTile(data._id)
    if (res.status == "ok") {
      this.toastrService.success($localize`:@@globalSaved:Gespeichert!`);
    }
    this.overlay.unsetSelected();
    this.tileService.listTiles().then(m => this.frontendService.fromArray(m))

  }


  ngAfterViewInit(): void {
    this.frontendService.results$.subscribe(r => {
      if (r && r.length > 0 && this.overlayService.get("selected")) {
        if (r.find(u => u._id.toString() == this.overlayService.get("selected")) == undefined) {
          this.overlay.unsetSelected();
          this.overlayNew.unsetSelected();
          return;
        }
        this.overlay.setSelected(r.find(u => u._id.toString() == this.overlayService.get("selected")))
      }
    })

    this.overlay.opened$.subscribe(r => {
      if (!r) {
        this.table.selectedObject = undefined;
      }
    })
  }

  public open() {

    let tile: Tile = {
      "_id": new ObjectId(),
      "name": "",
      "showByDefault": false,
      "text": "",
      "subtext": "",
      "url": "",
      "img": undefined,
      "openInNewTab": false,
      "color": "#ff00ff",
      "icon": '',
      "permissions": []
    }

    this.overlayNew.setSelected(tile)
  }

}
